/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import api from '../api';

interface Props {
  children: JSX.Element;
}

export interface ResponseData {
  count: number;
  data: any[];
  page: number;
  totalItems: number;
  totalPages: number;
}

interface PeopleContextProps {
  getPeople: (page: number, size: number) => void;
  people: ResponseData;
  setFilter: (value: any) => void;
  filter: any;
  setPeople: (value: any) => void;
  loading: boolean;
}

export const PeopleContext = React.createContext<PeopleContextProps>(
  {} as PeopleContextProps
);

export const PeopleStorage = ({ children }: Props) => {
  const [people, setPeople] = React.useState<ResponseData>({} as ResponseData);
  const [filter, setFilter] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);

  async function getPeople(page: number, size: number) {
    setLoading(true);
    setPeople({ count: 0, data: [], page: 0, totalItems: 0, totalPages: 0 });
    try {
      const res = await api.get(
        `/pacientes?page=${page}&size=${size}&${new URLSearchParams(
          filter
        ).toString()}`
      );

      if (
        filter.cns ||
        filter.nome ||
        filter.dt_nascimento ||
        filter.cpf ||
        filter.nome_mae ||
        filter.indefinidos === true
      ) {
        setPeople(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setPeople({
          data: [
            {
              id: 0,
              nome: '',
              nome_mae: '',
              cpf: '',
              dt_nascimento: null,
              cns: '',
            },
          ],
          page: 1,
          count: 0,
          totalPages: 1,
          totalItems: 0,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  const hasFilter =
    !!filter.cns ||
    !!filter.nome ||
    !!filter.dt_nascimento ||
    !!filter.cpf ||
    !!filter.nome_mae;

  React.useEffect(() => {
    if (hasFilter) {
      getPeople(1, 25);
    }
  }, [filter, hasFilter]);

  return (
    <PeopleContext.Provider
      value={{ people, getPeople, filter, setFilter, setPeople, loading }}
    >
      {children}
    </PeopleContext.Provider>
  );
};
