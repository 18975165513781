import { TableCell, Typography } from '@mui/material';
import {
  valuesBooleanos,
  valuesInspecaoColo,
  valuesLocalColeta,
  valuesMetodoColeta,
  valuesMotivoNaoAtendida,
} from '../../../variablesRowsValues';
import moment from 'moment';
import CollapseColumnText from '../../../../../CollapseColumnText';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

const RowsClinico = ({ row }) => {
  const [openedRow, setOpenedRow] = useState<any>();

  const useStyles = makeStyles({
    table: {
      minWidth: 750,
      border: 'none',
      '& td': {
        borderBottom: 'none',
      },
    },
    icon: {
      fontSize: 20,
    },
  });

  const classes = useStyles();

  const inspecao = {
    NORMAL: 'Normal',
    AUSENTE: 'Ausente (anomalias congênitas ou retirado cirurgicamente)',
    ALTERADO: 'Alterado',
    NAO_VISUALIZADO: 'Colo Não Visualizado',
  };

  const statusExame = {
    RESULTADO_DISPONIVEL: 'Resultado disponível',
    RESULTADO_NAO_ENTREGUE: 'Resultado não entregue',
    ENTREGUE: 'Entregue',
    RECOMENDADO: 'Recomendado',
    COLETADO: 'Coletado',
    ENVIADO_PARA_ANALISE: 'Enviado para análise',
    EM_ANALISE: 'Em análise',
    DISPONIVEL: 'Disponível',
    NAO_ENTREGUE: 'Não entregue',
    FALHA: 'Falha',
    NAO_ATENDIDA: 'Não atendida',
  };

  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {inspecao[row?.clinico?.inspecao]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.clinico?.dst === true && 'Sim'}
          {row?.clinico?.dst === false && 'Não'}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.clinico?.autocoleta === true && 'Autocoleta'}
          {row?.clinico?.autocoleta === false && 'Profissional de Saúde'}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.clinico?.dt_coleta
            ? moment(row.clinico.dt_coleta).format('DD/MM/YYYY')
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional_responsavel?.pessoa?.nome
            ? String(row?.profissional_responsavel?.pessoa?.nome).toUpperCase()
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesLocalColeta[row?.clinico?.local_coleta]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.id
            ? row?.procedimento?.estabelecimento_saude?.nome_fantasia
            : row?.profissional_responsavel?.estabelecimentos?.[0]
                ?.estabelecimento?.nome_fantasia}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px', height: '81px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumnText
            text={row?.atendimento_observacao}
            valueToOpen="atendimento_observacao"
            classes={classes}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
          />
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {statusExame[row?.status]}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsClinico;
