/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';

import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../../api';
import { useStyles } from '../ViewExamStyle';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { Escolha, Motivo } from './Hpv';
import { Anamnese } from '../../RegisterExam/Exams/Hpv';
import { getUserToken } from '../../../lib/auth';
import { LoadingContext } from '../../../context/LoadingContext';
import { useContext, useEffect, useState } from 'react';
enum ExmVulva {
  SemAlteracoes = 'SEM_ALTERAÇÕES',
  ComAlteracoes = 'COM_ALTERAÇÕES',
}

enum AvalGeral {
  Adequada = 'ADEQUADA',
  Inadequada = 'INADEQUADA',
}

enum JuncaoEscamoColunar {
  CompletamenteVisivel = 'COMPLETAMENTE_VISIVEL',
  ParcialmenteVisivel = 'PARCIALMENTE_VISIVEL',
  NaoVisivel = 'NAO_VISIVEL',
}

enum TipoAchados {
  AchadoColposcopicoNormal = 'ACHADO_COLPOSCOPICO_NORMAL',
  AchadoColposcopicoAnormal = 'ACHADO_COLPOSCOPICO_ANORMAL',
}

enum ZonaTransformacao {
  DentroDaZT = 'DENTRO_DA_ZT',
  ForaDaZT = 'FORA_DA_ZT',
  Tipo1 = 'TIPO_1',
  Tipo2 = 'TIPO_2',
  Tipo3 = 'TIPO_3',
}

enum NaoEspecifico {
  Leucoplasia = 'LEUCOPLASIA',
  Erosao = 'EROSAO',
}

enum Lugol {
  Positiva = 'POSITIVA',
  Negativa = 'NEGATIVA',
}

enum Biopsia {
  Sim = 'SIM',
  Nao = 'NAO',
}

export enum LocalBiopsia {
  Colo,
  Vagina,
}

enum Schiller {
  Negativo = 'NEGATIVO',
  Positivo = 'POSITIVO',
}

interface Inadequada {
  SangramentoIntenso: boolean;
  Cicatriz: boolean;
  InflamacaoIntensa: boolean;
  AtrofiaIntensa: boolean;
}

interface Local {
  Colo: boolean;
  ZonaTransformacao: ZonaTransformacao;
  qntQuadrantes: number;
  Vagina: boolean;
}

interface Menor {
  EpitelioTenue: boolean;
  MosaicoFino: boolean;
  PontilhadoFino: boolean;
}

interface Maior {
  EpitelioDenso: boolean;
  Acetobranqueamento: boolean;
  OrificiosGlandulares: boolean;
  MosaicoGrosseiro: boolean;
  PontilhadoGrosseiro: boolean;
  MargemDemarcada: boolean;
  SinalMargem: boolean;
  SinalCrista: boolean;
}

interface SuspeitaInvasao {
  VasosAtipicos: boolean;
  SinaisAdicionais: boolean;
}

interface ColposcopicosAnormal {
  Local: Local;
  Menor: Menor;
  Maior: Maior;
  NaoEspecifico: NaoEspecifico;
  SuspeitaInvasao: SuspeitaInvasao;
}

interface Miscelanea {
  Miscelanea: boolean;
  ZonaCongenita: boolean;
  Condiloma: boolean;
  Polopo: boolean;
  Inflamacao: boolean;
  Estenose: boolean;
  AnomaliaCongenita: boolean;
  SequelaPosTratamento: boolean;
  SugestivoEndometriose: boolean;
}

interface ColposcopiaType {
  dt_exame: string;
  exmVulva: ExmVulva;
  avalGeral: AvalGeral;
  inadequada: Inadequada;
  juncaoEscamoColunar: JuncaoEscamoColunar;
  zonaTransformacao: ZonaTransformacao;
  tipoAchados: TipoAchados;
  colposcopicosAnormal: ColposcopicosAnormal;
  miscelanea: Miscelanea;
  lugol: Lugol;
  schiller: Schiller;
  observacoes: string;
  biopsia: Biopsia;
  localBiopsia: LocalBiopsia[];
  semBiopsia: boolean;
}

interface Inputs {
  colposcopia: ColposcopiaType;
  pessoaId: number;
  profissionalId: number;
  responsavelId: number;
  estabelecimentoSaudeId: any;
  anamnese: Anamnese;
  cbo: string;
  cns: string;
  rede_atendimento: string;
  cnes: string;
  protocolo: string;
}

const schema = Yup.object({});

export default function Colposcopia({ data: otherData, examId }) {
  const styles = useStyles();
  const location = useLocation();
  const { id } = useParams();
  const [, setPacienteData] = useState<any>();
  const [, setResponsaveis] = useState<any>([]);
  const [checkedMenstruacao] = useState();
  const [data, setData] = useState<any>();
  const [optionsResponsaveis, setOptionsResponsaveis] = useState([]);
  const [disableInputResponsavel, setDisableInputResponsavel] = useState(false);
  const [tipoAchado, setTipoAchado] = useState<any>({
    normal: false,
    anormal: true,
  });
  const [cnesEstabelecimento, setCnesEstabelecimento] = useState<any>();
  const { setLoading } = useContext(LoadingContext);
  const {
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      colposcopia: {
        localBiopsia: [],
      },
    },
    resolver: yupResolver(schema),
  });

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },
  };

  const styleCheckBox = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  useEffect(() => {
    async function loadData(id: any) {
      await api
        .get('/pessoas/' + id)
        .then((response) => {
          setPacienteData(response.data);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadData(id);
  }, [id]);

  useEffect(() => {
    async function loadDataForm() {
      await api.get(`/colposcopias/${otherData.id}`).then((response) => {
        setData(response.data);
      });
    }

    loadDataForm();
  }, [otherData]);

  let navigate = useNavigate();

  useEffect(() => {
    setPacienteData(location.state);
    async function loadResponsaveis() {
      const estabelecimentos = await api.get('/estabelecimentos-saude');
      await api
        .get('/profissionais')
        .then((response) => {
          console.log(estabelecimentos);
          setResponsaveis(response.data?.data);
          // setValue('estabelecimentoSaudeId', estabelecimentos.data?.data[3].id);
          setValue('responsavelId', response.data?.data[0].id);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadResponsaveis();
  }, []);

  const handleBackPage = () => {
    navigate(`/pacientes/${id}`);
  };

  const objJuncaoEscamoColunar = {
    1: JuncaoEscamoColunar.CompletamenteVisivel,
    2: JuncaoEscamoColunar.ParcialmenteVisivel,
    3: JuncaoEscamoColunar.NaoVisivel,
  };

  const objZonaTransformacao = {
    1: ZonaTransformacao.Tipo1,
    2: ZonaTransformacao.Tipo2,
    3: ZonaTransformacao.Tipo3,
  };

  const objTipoAchados = {
    1: TipoAchados.AchadoColposcopicoNormal,
    2: TipoAchados.AchadoColposcopicoAnormal,
  };

  const objLocalizacaoZonaTransformacao = {
    1: ZonaTransformacao.DentroDaZT,
    2: ZonaTransformacao.ForaDaZT,
  };
  useEffect(() => {
    if (data?.id) {
      setLoading(false);
      setValue('rede_atendimento', data?.rede_atendimento);
      setValue(
        'estabelecimentoSaudeId',
        data?.procedimento?.estabelecimento_saude?.nome_fantasia
      );
      setCnesEstabelecimento(data?.procedimento?.estabelecimento_saude?.cnes);

      setValue('protocolo', data?.protocolo);
      setValue(
        'colposcopia.exmVulva',
        data?.exame_macroscopico === 2
          ? ExmVulva.ComAlteracoes
          : ExmVulva.SemAlteracoes
      );
      setValue(
        'colposcopia.avalGeral',
        data?.avaliacao_geral === 1 ? AvalGeral.Adequada : AvalGeral.Inadequada
      );

      setValue(
        'colposcopia.juncaoEscamoColunar',
        objJuncaoEscamoColunar[data?.visibilidade_juncao_escamocolunar]
      );
      setValue(
        'colposcopia.zonaTransformacao',
        objZonaTransformacao[data?.zona_transformacao]
      );
      setValue('colposcopia.tipoAchados', objTipoAchados[data?.tipo_achados]);
      if (data?.tipo_achados === 1) {
        setTipoAchado({ normal: true, anormal: false });
      }
      if (data?.tipo_achados === 2) {
        setTipoAchado({ normal: false, anormal: true });
      }
      if (data?.tipo_achados === 3) {
        setTipoAchado({ normal: true, anormal: true });
      }
      setValue('colposcopia.dt_exame', data?.dt_coleta);
      setValue('colposcopia.localBiopsia', [
        LocalBiopsia.Colo,
        LocalBiopsia.Vagina,
      ]);
      setValue(
        'colposcopia.lugol',
        data?.captacao_lugol ? Lugol.Positiva : Lugol.Negativa
      );
      setValue(
        'colposcopia.schiller',
        data?.teste_schiller ? Schiller.Positivo : Schiller.Negativo
      );
      setValue(
        'colposcopia.biopsia',
        data?.realizar_biopsia ? Biopsia.Sim : Biopsia.Nao
      );
      setValue('colposcopia.semBiopsia', data?.sem_biopsia_previa);
      setValue(
        'colposcopia.colposcopicosAnormal.Local.Colo',
        data?.aspectos_achados_anormais?.localizacao_colo
      );
      setValue(
        'colposcopia.colposcopicosAnormal.Local.ZonaTransformacao',
        objLocalizacaoZonaTransformacao[
          data?.aspectos_achados_anormais?.localizacao_zona_de_transformacao
        ]
      );

      setValue(
        'colposcopia.colposcopicosAnormal.Local.qntQuadrantes',
        data?.aspectos_achados_anormais?.n_quadrantes
      );

      setValue(
        'profissionalId',
        data?.procedimento?.profissional?.pessoa?.nome
      );
      setValue('cbo', data?.procedimento?.profissional?.cbo);
      setValue('cns', data?.procedimento?.profissional?.cns);

      // Set dados de anamnese

      setValue('anamnese.motivo', data?.anamnese?.motivo);
      setValue('anamnese.papanicolaou', data?.anamnese?.papanicolaou);
      setValue('anamnese.ano_papanicolaou', data?.anamnese?.ano_papanicolaou);
      setValue('anamnese.diu', data?.anamnese?.diu);
      setValue('anamnese.gravida', data?.anamnese?.gravida);
      setValue('anamnese.anticoncepcional', data?.anamnese?.anticoncepcional);
      setValue('anamnese.hormonio', data?.anamnese?.hormonio);
      setValue('anamnese.radioterapia', data?.anamnese?.radioterapia);
      setValue('anamnese.dt_menstruacao', data?.anamnese?.dt_menstruacao);
      setValue(
        'anamnese.sangramento_relacao',
        data?.anamnese?.sangramento_relacao
      );
      setValue(
        'anamnese.sangramento_menopausa',
        data?.anamnese?.sangramento_menopausa
      );
    }
  }, [data]);

  const generatePDF = async (storedExamDataId) => {
    try {
      const response = await api.get(
        `/reports/coloscopia/${storedExamDataId}`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  const rhf = watch();
  const storedExamDataId = examId;

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="outlined"
          onClick={() => generatePDF(storedExamDataId)}
          startIcon={<DownloadIcon />}
        >
          EXPORTAR
        </Button>
      </Box>
      <Box sx={{ width: '100%' }}>
        <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
          <i>* Resposta obrigatória</i>
        </p>
        <form onSubmit={() => {}}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}></Paper>

            <Paper
              variant="outlined"
              sx={{ width: '100%', borderRadius: '10px' }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ margin: '25px', paddingTop: '25px' }}
              >
                Resultado do Exame
              </Typography>
              <Divider />
              <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptBR}
                    >
                      <div>
                        <Controller
                          control={control}
                          name="colposcopia.dt_exame"
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              onChange={onChange}
                              mask="__/__/____"
                              value={value ? value : null}
                              renderInput={(params) => (
                                <StyledAsteriskTextField
                                  {...params}
                                  required
                                  style={{ marginTop: '20px' }}
                                  label="Data da Colposcopia"
                                  size="small"
                                  variant="outlined"
                                  className={styles.textField}
                                  fullWidth
                                />
                              )}
                              disabled
                            />
                          )}
                        />
                        <FormHelperText>
                          {errors.colposcopia?.dt_exame?.message}
                        </FormHelperText>
                      </div>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error={errors.colposcopia?.exmVulva && true}>
                      <FormLabel sx={styleFormLabel}>
                        Exame macroscópico da vulva e região perianal
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={ExmVulva.SemAlteracoes}
                              control={<Radio disabled sx={styleRadio} />}
                              label="Sem Alterações"
                            />
                            <FormControlLabel
                              value={ExmVulva.ComAlteracoes}
                              control={<Radio disabled sx={styleRadio} />}
                              label="Com Alterações"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.exmVulva"
                        control={control}
                      />
                      <FormHelperText>
                        {errors.colposcopia?.exmVulva?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl error={errors.colposcopia?.avalGeral && true}>
                      <FormLabel sx={styleFormLabel}>
                        Avaliação Geral da Colposcopia
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={AvalGeral.Adequada}
                              control={<Radio disabled sx={styleRadio} />}
                              label="Adequada"
                            />
                            <FormControlLabel
                              value={AvalGeral.Inadequada}
                              control={<Radio disabled sx={styleRadio} />}
                              label="Inadequada"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.avalGeral"
                        control={control}
                      />
                      <FormHelperText>
                        {errors.colposcopia?.avalGeral?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {watch('colposcopia.avalGeral') === AvalGeral.Inadequada && (
                    <Grid item xs={12}>
                      <FormLabel sx={styleFormLabel}>
                        Inadequado por:{' '}
                      </FormLabel>
                      <Box sx={{ marginLeft: '15px' }}>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  {...field}
                                  sx={styleCheckBox}
                                  checked={data?.motivo_inadequada?.[0]?.value}
                                />
                              }
                              label="Sangramento Intenso"
                            />
                          )}
                          name="colposcopia.inadequada.SangramentoIntenso"
                        />

                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  {...field}
                                  sx={styleCheckBox}
                                  checked={data?.motivo_inadequada?.[1]?.value}
                                />
                              }
                              label="Cicatriz"
                            />
                          )}
                          name="colposcopia.inadequada.Cicatriz"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  {...field}
                                  sx={styleCheckBox}
                                  checked={data?.motivo_inadequada?.[2]?.value}
                                />
                              }
                              label="Inflamação Intensa"
                            />
                          )}
                          name="colposcopia.inadequada.InflamacaoIntensa"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  {...field}
                                  sx={styleCheckBox}
                                  checked={data?.motivo_inadequada?.[3]?.value}
                                />
                              }
                              label="Atrofia Intensa"
                            />
                          )}
                          name="colposcopia.inadequada.AtrofiaIntensa"
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormLabel sx={styleFormLabel}>
                      Visibilidade da junção escamocolunar
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value ? value : ''}
                          row
                          sx={{ marginLeft: '15px' }}
                        >
                          <FormControlLabel
                            value={JuncaoEscamoColunar.CompletamenteVisivel}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Completamente Visivel"
                          />
                          <FormControlLabel
                            value={JuncaoEscamoColunar.ParcialmenteVisivel}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Parcialmente Visivel"
                          />
                          <FormControlLabel
                            value={JuncaoEscamoColunar.NaoVisivel}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Nao Visivel"
                          />
                        </RadioGroup>
                      )}
                      name="colposcopia.juncaoEscamoColunar"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel sx={styleFormLabel}>
                      Zona de Transformação (ZT)
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value ? value : ''}
                          row
                          sx={{ marginLeft: '15px' }}
                        >
                          <FormControlLabel
                            value={ZonaTransformacao.Tipo1}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Tipo 1"
                          />
                          <FormControlLabel
                            value={ZonaTransformacao.Tipo2}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Tipo 2"
                          />
                          <FormControlLabel
                            value={ZonaTransformacao.Tipo3}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Tipo 3"
                          />
                        </RadioGroup>
                      )}
                      name="colposcopia.zonaTransformacao"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel sx={styleFormLabel}>Tipo de Achados</FormLabel>

                    <RadioGroup row sx={{ marginLeft: '15px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={styleRadio}
                            checked={tipoAchado.normal}
                            disabled
                          />
                        }
                        label="Achado Colposcópico Normal"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={styleRadio}
                            checked={tipoAchado.anormal}
                            disabled
                          />
                        }
                        label="Achado Colposcópico Anormal"
                      />
                    </RadioGroup>
                  </Grid>
                  {((tipoAchado.normal === true &&
                    tipoAchado.anormal === true) ||
                    tipoAchado.anormal === true) && (
                    <Paper sx={{ width: '100%', marginLeft: '25px' }}>
                      <Typography style={{ margin: '25px' }}>
                        Aspectos dos Achados Colposcópicos Anormais
                      </Typography>
                      <Divider sx={{ margin: '25px' }} />
                      <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Localização
                            </FormLabel>
                            <Box
                              sx={{
                                marginLeft: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Controller
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled
                                        checked={
                                          data?.aspectos_achados_anormais
                                            ?.localizacao_colo === true
                                            ? true
                                            : false
                                        }
                                        {...field}
                                        sx={styleCheckBox}
                                      />
                                    }
                                    label="Colo"
                                  />
                                )}
                                name="colposcopia.colposcopicosAnormal.Local.Colo"
                              />
                              {watch(
                                'colposcopia.colposcopicosAnormal.Local.Colo'
                              ) && (
                                <Box sx={{ marginLeft: '25px' }}>
                                  <Grid item xs={12}>
                                    <FormLabel sx={styleFormLabel}>
                                      Localização em relação a Zona de
                                      Transformação (ZT)
                                    </FormLabel>
                                    <Controller
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <RadioGroup
                                          onChange={onChange}
                                          value={value ? value : ''}
                                          row
                                        >
                                          <FormControlLabel
                                            value={ZonaTransformacao.DentroDaZT}
                                            control={
                                              <Radio disabled sx={styleRadio} />
                                            }
                                            label="Dentro da ZT"
                                          />
                                          <FormControlLabel
                                            value={ZonaTransformacao.ForaDaZT}
                                            control={
                                              <Radio disabled sx={styleRadio} />
                                            }
                                            label="Fora da ZT"
                                          />
                                        </RadioGroup>
                                      )}
                                      name="colposcopia.colposcopicosAnormal.Local.ZonaTransformacao"
                                      control={control}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormLabel sx={styleFormLabel}>
                                      Nº de quadrantes envolvidos
                                    </FormLabel>
                                    <Controller
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <RadioGroup
                                          onChange={onChange}
                                          value={value ? value : ''}
                                          row
                                        >
                                          <FormControlLabel
                                            value={1}
                                            control={
                                              <Radio disabled sx={styleRadio} />
                                            }
                                            label="1"
                                          />
                                          <FormControlLabel
                                            value={2}
                                            control={
                                              <Radio disabled sx={styleRadio} />
                                            }
                                            label="2"
                                          />
                                          <FormControlLabel
                                            value={3}
                                            control={
                                              <Radio disabled sx={styleRadio} />
                                            }
                                            label="3"
                                          />
                                          <FormControlLabel
                                            value={4}
                                            control={
                                              <Radio disabled sx={styleRadio} />
                                            }
                                            label="4"
                                          />
                                        </RadioGroup>
                                      )}
                                      name="colposcopia.colposcopicosAnormal.Local.qntQuadrantes"
                                      control={control}
                                    />
                                  </Grid>
                                </Box>
                              )}
                              <Controller
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled
                                        {...field}
                                        sx={styleCheckBox}
                                        checked={
                                          data?.aspectos_achados_anormais
                                            ?.localizacao_vagina === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Vagina"
                                  />
                                )}
                                name="colposcopia.colposcopicosAnormal.Local.Vagina"
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Grau 1 (Menor)
                            </FormLabel>
                            <Box sx={{ marginLeft: '15px' }}>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_menor.find(
                                                  (item) =>
                                                    item.grau_menor.id === 1
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Epitélio acetobranco tênue, borda irregular ou geográfica"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Menor.EpitelioTenue"
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_menor.find(
                                                  (item) =>
                                                    item.grau_menor.id === 2
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Mosaico fino, borda irregular e geográfica"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Menor.MosaicoFino"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_menor.find(
                                                  (item) =>
                                                    item.grau_menor.id === 3
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Pontilhado fino, borda irregular ou geográfica"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Menor.PontilhadoFino"
                                />
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Grau 2 (Maior)
                            </FormLabel>
                            <Box sx={{ marginLeft: '15px' }}>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 1
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Epitélio acetobranco denso"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.EpitelioDenso"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 2
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Acetobranqueamento de aparecimento rápido"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.Acetobranqueamento"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 3
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Orifícios glandulares espessados"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.OrificiosGlandulares"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 4
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Mosaico grosseiro"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.MosaicoGrosseiro"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 5
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Pontilhado grosseiro"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.PontilhadoGrosseiro"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 6
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Margem demarcada"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.MargemDemarcada"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 7
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Sinal da margem interna"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.SinalMargem"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          {...field}
                                          sx={styleCheckBox}
                                          checked={
                                            data
                                              ? data?.aspectos_achados_anormais?.grau_maior.find(
                                                  (item) =>
                                                    item.grau_maior.id === 8
                                                ).value
                                              : false
                                          }
                                        />
                                      }
                                      label="Sinal da crista (sobrelevado)"
                                    />
                                  )}
                                  name="colposcopia.colposcopicosAnormal.Maior.SinalCrista"
                                />
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Não especifico
                            </FormLabel>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <RadioGroup
                                  onChange={onChange}
                                  value={value ? value : ''}
                                  sx={{ marginLeft: '15px' }}
                                >
                                  <FormControlLabel
                                    value={NaoEspecifico.Leucoplasia}
                                    control={
                                      <Radio
                                        disabled
                                        sx={styleRadio}
                                        checked={
                                          data?.aspectos_achados_anormais
                                            ?.nao_especifico === 1
                                        }
                                      />
                                    }
                                    label="Leucoplasia (queratose, hiperqueratose)"
                                  />
                                  <FormControlLabel
                                    value={NaoEspecifico.Erosao}
                                    control={
                                      <Radio
                                        disabled
                                        sx={styleRadio}
                                        checked={
                                          data?.aspectos_achados_anormais
                                            ?.nao_especifico === 2
                                        }
                                      />
                                    }
                                    label="Erosão"
                                  />
                                </RadioGroup>
                              )}
                              name="colposcopia.colposcopicosAnormal.NaoEspecifico"
                              control={control}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Suspeita de Invasão
                            </FormLabel>
                            <Box sx={{ marginLeft: '15px' }}>
                              <Controller
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled
                                        {...field}
                                        sx={styleCheckBox}
                                        checked={
                                          data
                                            ? data?.aspectos_achados_anormais?.suspeita_de_invasao.find(
                                                (item) =>
                                                  item.suspeitas_invasao.id ===
                                                  1
                                              ).value
                                            : false
                                        }
                                      />
                                    }
                                    label="Vasos atípicos"
                                  />
                                )}
                                name="colposcopia.colposcopicosAnormal.SuspeitaInvasao.VasosAtipicos"
                              />
                              <Controller
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled
                                        {...field}
                                        sx={styleCheckBox}
                                        checked={
                                          data
                                            ? data?.aspectos_achados_anormais?.suspeita_de_invasao.find(
                                                (item) =>
                                                  item.suspeitas_invasao.id ===
                                                  2
                                              ).value
                                            : false
                                        }
                                      />
                                    }
                                    label="Sinais adicionais: vasos frágeis, superfície irregular, lesão exofítica, necrose, ulceração (necrótica), neoplasia tumoral/grosseira"
                                  />
                                )}
                                name="colposcopia.colposcopicosAnormal.SuspeitaInvasao.SinaisAdicionais"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  )}
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              {...field}
                              sx={styleCheckBox}
                              checked={data?.miscelania === true ? true : false}
                            />
                          }
                          label="Miscelânea"
                        />
                      )}
                      name="colposcopia.miscelanea.Miscelanea"
                    />
                    {data?.miscelania && (
                      <Box
                        sx={{
                          marginLeft: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 1
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Zona de transformação congênita"
                            />
                          )}
                          name="colposcopia.miscelanea.ZonaCongenita"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 2
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Condiloma"
                            />
                          )}
                          name="colposcopia.miscelanea.Condiloma"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 3
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Pólipo (ectocervical / endocervical)"
                            />
                          )}
                          name="colposcopia.miscelanea.Polopo"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 4
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Inflamação"
                            />
                          )}
                          name="colposcopia.miscelanea.Inflamacao"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 5
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Estenose"
                            />
                          )}
                          name="colposcopia.miscelanea.Estenose"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 6
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Anomalia congênita"
                            />
                          )}
                          name="colposcopia.miscelanea.AnomaliaCongenita"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 7
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Sequela pós tratamento"
                            />
                          )}
                          name="colposcopia.miscelanea.SequelaPosTratamento"
                        />
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    data?.resultados_miscelania.find(
                                      (item) => item.miscelania.id === 8
                                    ).value
                                  }
                                  {...field}
                                  sx={styleCheckBox}
                                />
                              }
                              label="Sugestivo de Endometriose"
                            />
                          )}
                          name="colposcopia.miscelanea.SugestivoEndometriose"
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel sx={styleFormLabel}>
                      Captação da solução de lugol
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value ? value : ''}
                          row
                          sx={{ marginLeft: '15px' }}
                        >
                          <FormControlLabel
                            value={Lugol.Positiva}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Positiva (corado)"
                          />
                          <FormControlLabel
                            value={Lugol.Negativa}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Negativa (não corado)"
                          />
                        </RadioGroup>
                      )}
                      name="colposcopia.lugol"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel sx={styleFormLabel}>Teste de Schiller</FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value ? value : ''}
                          row
                          sx={{ marginLeft: '15px' }}
                        >
                          <FormControlLabel
                            value={Schiller.Negativo}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Negativo"
                          />
                          <FormControlLabel
                            value={Schiller.Positivo}
                            control={<Radio disabled sx={styleRadio} />}
                            label="Positivo"
                          />
                        </RadioGroup>
                      )}
                      name="colposcopia.schiller"
                      control={control}
                    />
                  </Grid>
                  <Box
                    sx={{
                      marginLeft: '25px',
                      width: '100%',
                      height: '90px',
                      marginTop: '24px',
                    }}
                  >
                    <TextField
                      value={data?.observacoes || ''}
                      multiline
                      disabled
                      rows={3}
                      sx={{ width: '100%' }}
                      label="Observações e informações adicionais"
                    />
                  </Box>
                </Grid>
              </Box>
            </Paper>

            <Paper
              variant="outlined"
              sx={{ width: '100%', borderRadius: '10px', marginTop: '24px' }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ margin: '25px', paddingTop: '25px' }}
              >
                Encaminhamento da Colposcopia
              </Typography>
              <Divider sx={{ margin: '25px' }} />
              <Box sx={{ margin: '25px', marginBottom: '40px' }}>
                <Grid item xs={12}>
                  <FormLabel sx={styleFormLabel}>Realizada Biópsia</FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={onChange}
                        value={value ? value : ''}
                        row
                        sx={{ marginLeft: '15px' }}
                      >
                        <FormControlLabel
                          value={Biopsia.Sim}
                          control={<Radio disabled sx={styleRadio} />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value={Biopsia.Nao}
                          control={<Radio disabled sx={styleRadio} />}
                          label="Não"
                        />
                      </RadioGroup>
                    )}
                    name="colposcopia.biopsia"
                    control={control}
                  />
                </Grid>

                {data?.realizar_biopsia === true && (
                  <Grid item xs={12}>
                    <FormLabel>Localização da Realização da Biópsia</FormLabel>

                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          value={value ? value : ''}
                          row
                          sx={{ marginLeft: '15px' }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={styleCheckBox}
                                disabled
                                checked={
                                  data?.localizacao_biopsia.find(
                                    (value) => value === 'COLO'
                                  )
                                    ? true
                                    : false
                                }
                              />
                            }
                            label="Colo"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={styleCheckBox}
                                disabled
                                checked={
                                  data?.localizacao_biopsia.find(
                                    (value) => value === 'VAGINA'
                                  )
                                    ? true
                                    : false
                                }
                              />
                            }
                            label="Vagina"
                          />
                        </RadioGroup>
                        // <FormControlLabel
                        //   control={
                        //     <Checkbox
                        //       disabled
                        //       checked={
                        //         data?.localizacao_biopsia.find(
                        //           (value) => value === 'COLO'
                        //         )
                        //           ? true
                        //           : false
                        //       }
                        //     />
                        //   }
                        //   label="Colo"
                        // />
                        // <FormControlLabel
                        //   control={
                        //     <Checkbox
                        //       disabled
                        //       checked={
                        //         data?.localizacao_biopsia.find(
                        //           (value) => value === 'VAGINA'
                        //         )
                        //           ? true
                        //           : false
                        //       }
                        //     />
                        //   }
                        //   label="Vagina"
                        // />
                      )}
                      name="colposcopia.localBiopsia"
                      control={control}
                    />
                  </Grid>
                )}

                {watch('colposcopia.biopsia') === Biopsia.Nao && (
                  <Box sx={{ marginLeft: '15px' }}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data?.sem_biopsia_previa}
                              disabled
                              {...field}
                              sx={styleCheckBox}
                            />
                          }
                          label="Indicado EZT sem Biópsia Prévia (ver e tratar)"
                        />
                      )}
                      name="colposcopia.semBiopsia"
                    />
                  </Box>
                )}
              </Box>
            </Paper>

            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <Box p={3}>
                <Typography variant="h5" fontWeight="bold">
                  Identificação do Laboratório
                </Typography>

                <Box my={3}>
                  <Divider />
                </Box>

                <FormControl>
                  <FormLabel
                    required
                    sx={styleFormLabel}
                    id="rede-atendimento"
                    error={!!errors?.rede_atendimento}
                  >
                    Rede de Atendimento
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      console.log(value);
                      return (
                        <RadioGroup onChange={onChange} value={value} row>
                          <FormControlLabel
                            value={1}
                            control={
                              <Radio
                                sx={styleRadio}
                                checked={Number(value) === 1}
                              />
                            }
                            label="Rede SUS"
                            disabled
                          />
                          <FormControlLabel
                            value={2}
                            control={
                              <Radio
                                sx={styleRadio}
                                checked={Number(value) === 2}
                              />
                            }
                            label="Saúde suplementar"
                            disabled
                          />
                        </RadioGroup>
                      );
                    }}
                    name="rede_atendimento"
                    control={control}
                  />
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {errors.rede_atendimento?.message}
                  </FormHelperText>
                </FormControl>

                <Box mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <StyledAsteriskTextField
                        fullWidth
                        label="CNES do Laboratório"
                        required
                        size="small"
                        error={!!errors?.cnes}
                        helperText={errors?.cnes?.message}
                        value={cnesEstabelecimento || ''}
                        variant="outlined"
                        className={styles.textField}
                        // onChange={(e) => {
                        //   const value = e.target.value.replace(/\D+/g, '');
                        //   if (value.length < 8) {
                        //     setOptionsResponsaveis([]);
                        //     setValue('cnes', value);
                        //     clearErrors('cnes');
                        //     setLoadingLaboratorios(true);
                        //     setCnesEstabelecimento(value);
                        //     debounceChange(value);
                        //     if (value) {
                        //       setValue('estabelecimentoSaudeId', {
                        //         nome_fantasia: 'Carregando...',
                        //       });
                        //     } else {
                        //       setValue('estabelecimentoSaudeId', null);
                        //     }
                        //   }
                        // }}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Controller
                        name="estabelecimentoSaudeId"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <TextField
                              required
                              disabled
                              value={value ?? ''}
                              InputLabelProps={{ shrink: !!value }}
                              variant="outlined"
                              label="Laboratório responsavél pelo Resultado"
                              size="small"
                              error={!!errors?.estabelecimentoSaudeId}
                              helperText={
                                errors?.estabelecimentoSaudeId?.message
                              }
                              fullWidth
                            />
                            // <Autocomplete
                            //   options={optionsEstabelecimentos}
                            //   value={value ? value : null}
                            //   disabled={disableLaboratorio}
                            //   noOptionsText={
                            //     loadingLaboratorios
                            //       ? 'Carregando...'
                            //       : 'Não há opções'
                            //   }
                            //   onChange={(_, newValue: any) => {
                            //     onChange(newValue);
                            //     setCnesEstabelecimento(newValue?.cnes);
                            //     getOptionsProfissionais(newValue?.id);
                            //     setValue('histopatologia.profissionalId', {
                            //       id: 0,
                            //     });
                            //     setValue('histopatologia.profissionalCBO', '');
                            //     setValue('histopatologia.profissionalCNS', '');
                            //   }}
                            //   getOptionLabel={(option: any) =>
                            //     option?.nome_fantasia
                            //   }
                            //   renderInput={(params) => (
                            //     <TextField
                            //       {...params}
                            //       required
                            //       variant="outlined"
                            //       label="Laboratório responsavél pelo Resultado"
                            //       size="small"
                            //       error={!!errors?.laboratorio_responsavel}
                            //       helperText={
                            //         errors?.laboratorio_responsavel?.message
                            //       }
                            //       fullWidth
                            //     />
                            //   )}
                            // />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            label="Número do Protocolo do Exame no SISCAN"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            value={value ? value : ''}
                            type="number"
                            disabled
                          />
                        )}
                        name="protocolo"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>

            <Paper
              variant="outlined"
              sx={{ borderRadius: '10px', marginTop: '24px' }}
            >
              <Box p={3}>
                <Typography variant="h5" fontWeight="bold">
                  Profissional Responsável
                </Typography>
                <Box my={3}>
                  <Divider />
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="profissionalId"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <StyledAsteriskTextField
                            fullWidth
                            required
                            value={value ? value : ''}
                            label="Profissional Responsável pelo Resultado"
                            size="small"
                            disabled
                            variant="outlined"
                            sx={{ backgroundColor: '#F0F0F0' }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      name="cbo"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <StyledAsteriskTextField
                            fullWidth
                            required
                            value={value ? value : ''}
                            label="CBO do Profissional"
                            size="small"
                            disabled
                            variant="outlined"
                            sx={{ backgroundColor: '#F0F0F0' }}
                          />
                        );
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      name="cns"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <StyledAsteriskTextField
                            fullWidth
                            required
                            value={value ? value : ''}
                            label="CNS do Profissional"
                            size="small"
                            disabled
                            variant="outlined"
                            sx={{ backgroundColor: '#F0F0F0' }}
                          />
                        );
                      }}
                    ></Controller>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          <p style={{ fontSize: '0.75rem', color: '#757575' }}>
            Adaptado da Terminologia IFCPC, Rio 2011
          </p>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '68px',
              borderRadius: 1,
            }}
            mb={3}
          >
            <Button
              sx={{
                marginTop: '10px',
                backgroundColor: '#17bebb',
              }}
              variant="contained"
              onClick={handleBackPage}
              startIcon={<CheckIcon />}
            >
              Voltar
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
