import { Button, Checkbox, Menu, MenuItem, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useStatesFields from '../../hooks/useStatesFields';
import useHandleSelect from '../../hooks/useHandleSelect';
import { StyledAsteriskTextField } from '../../../StyledAsteriskTextField/styles';
import { getUserToken } from '../../../../lib/auth';
import { getEstados } from '../../../../lib/getEstados';

const ModalEstado = ({
  anchorElEstados,
  searchEstado,
  estadosOptions,
  allEstadosOptions,
  estadosSelected,
  setAnchorElEstados,
  setSearchEstado,
  setEstadosOptions,
  setAllEstadosOptions,
  setEstadosSelected,
  disableInputEstado,
  errors,
  loadingEstados,
  setLoadingEstados,
}) => {
  const [filterText, setFilterText] = useState<string>('');

  const hasError = !!errors.find((error) => error === 'uf');

  useEffect(() => {
    if (estadosOptions.length > 0) {
      setLoadingEstados(false);
    }
  }, [estadosOptions]);

  const { handleSelectMultiplesFields } = useHandleSelect();

  const userToken = getUserToken();
  const isPadrao = userToken.permissao_atual.contexto === 'UNIDADE DE SAÚDE';
  const isEstadual = userToken.permissao_atual.id === 4;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isLaboratorio = userToken.permissao_atual.contexto === 'LABORATÓRIO';

  const filteredOptions = estadosOptions?.filter((option) =>
    option?.sigla?.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      <StyledAsteriskTextField
        required
        size="small"
        data-testid="modalEstadoInput"
        error={hasError}
        disabled={
          disableInputEstado ||
          isPadrao ||
          isEstadual ||
          isMunicipal ||
          isLaboratorio
        }
        helperText={hasError ? 'Campo Obrigatório' : undefined}
        label="UF"
        value={estadosSelected.length > 1 ? 'Vários' : estadosSelected[0] ?? ''}
        sx={{
          padding: 0,
          width: '100%',
          '.MuiOutlinedInput-root': {
            paddingRight: '9px',
          },
        }}
        InputProps={{
          endAdornment: (
            <Button
              data-testid="modalEstadoDropdownButton"
              sx={{
                padding: 0,
                minWidth: '24px !important',
              }}
              disabled={
                disableInputEstado ||
                isPadrao ||
                isEstadual ||
                isMunicipal ||
                isLaboratorio
              }
              onClick={(event) => {
                window.scrollTo(0, 0);
                setAnchorElEstados(event.currentTarget);
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  cursor: 'pointer',
                  color: 'gray',
                  // color: disableOtherInputs
                  //   ? '#BDBDBD'
                  //   : 'gray',
                }}
              />
            </Button>
          ),
        }}
      />

      <Menu
        anchorEl={anchorElEstados}
        open={!!anchorElEstados}
        onClose={() => {
          setAnchorElEstados(null);
          setTimeout(() => {
            setFilterText('');
          }, 500);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          position: 'absolute',
          width: '100%',
          marginTop: '10px',
          left: '10px',

          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '24px 32px',
            flexDirection: 'column',
          }}
        >
          <TextField
            size="small"
            data-testid="inputSearchEstado"
            placeholder="Pesquise por Sigla do Estado"
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
            value={filterText}
            sx={{
              width: '289px',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            // InputProps={{
            //   endAdornment: (
            //     <Button
            //       data-tesid="submitSearchEstado"
            //       sx={{
            //         padding: 0,
            //         minWidth: '24px !important',
            //       }}
            //       onClick={() => {
            //         if (!searchEstado) {
            //           setEstadosOptions(allEstadosOptions);
            //         } else {
            //           const newArrEstados = allEstadosOptions.filter((estado) =>
            //             estado.sigla
            //               .toLowerCase()
            //               .includes(searchEstado.toLowerCase())
            //           );
            //           setEstadosOptions(newArrEstados);
            //         }
            //       }}
            //     >
            //       <SearchIcon
            //         sx={{
            //           cursor: 'pointer',
            //           color: 'gray',
            //         }}
            //       />
            //     </Button>
            //   ),
            // }}
          />
          <div>
            <Button
              variant="text"
              sx={{ padding: '0' }}
              data-testid="selectAllEstados"
              onClick={() => {
                const allItemsArray = estadosOptions.map(
                  (estado: any) => estado.sigla
                );

                setEstadosSelected(allItemsArray);
              }}
            >
              SELECIONAR TODOS -{' '}
            </Button>
            <Button
              data-testid="clearEstados"
              variant="text"
              sx={{ padding: '0' }}
              onClick={() => setEstadosSelected([])}
            >
              LIMPAR
            </Button>
          </div>
          {loadingEstados ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <span
                className="loader"
                style={{ width: '40px', height: '40px' }}
              ></span>
            </div>
          ) : (
            <>
              {estadosOptions.includes('Não há dados') ? (
                <p>Não há dados</p>
              ) : (
                <>
                  {filteredOptions?.map((estado: any, index) => {
                    return (
                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          data-testid={`optionEstado-${index + 1}`}
                          onChange={() => {
                            handleSelectMultiplesFields({
                              paramToSave: 'sigla',
                              setState: setEstadosSelected,
                              state: estadosSelected,
                              value: estado,
                            });
                          }}
                          checked={
                            estadosSelected?.find(
                              (item) => item === estado.sigla
                            )
                              ? true
                              : false
                          }
                        />
                        <MenuItem
                          sx={{ padding: 0 }}
                          onClick={() => {
                            handleSelectMultiplesFields({
                              paramToSave: 'sigla',
                              setState: setEstadosSelected,
                              state: estadosSelected,
                              value: estado,
                            });
                          }}
                        >
                          {estado?.sigla}
                        </MenuItem>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default ModalEstado;
