/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';
import { maskCpf } from '../maskCpf';
import moment from 'moment';
import { PeopleContext } from '../../context/PeopleContext';
import CustomizedButton from '../CustomizedButton';
import ButtonNew from '../ButtonNew';
import CustomTablePagination from '../CustomTablePagination';
import { PerfilContext } from '../../context/PerfilContext';
import { formatTestId } from '../../utils/formatters';

interface Column {
  id: 'name' | 'sus' | 'birth_date' | 'cpf' | 'mother_name' | '';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'sus', label: 'Nº do Cartão SUS', minWidth: 170 },
  { id: 'name', label: 'Nome', minWidth: 100 },
  { id: 'birth_date', label: 'Data de Nascimento', minWidth: 170 },
  { id: 'cpf', label: 'CPF', minWidth: 170 },
  { id: 'mother_name', label: 'Nome da Mãe', minWidth: 170 },
  { id: '', label: '', minWidth: 170 },
];

export interface ResponseData {
  count: number;
  data: [];
  page: number;
  totalItems: number;
  totalPages: number;
}

export default function TablePatients(props) {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { getPeople, loading } = React.useContext(PeopleContext);
  const { hasPermission } = React.useContext(PerfilContext);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    getPeople(page + 1, Number(event.target.value));
    setPage(0);
  };
  const visualizarPaciente = (data: any) => {
    navigate(`/pacientes/` + data.id, { state: data });
  };

  // React.useEffect(() => {
  //   getPeople(page + 1, rowsPerPage);
  // }, [page, rowsPerPage]);

  return (
    <Box
      sx={{ width: '100%', marginTop: '15px', marginBottom: '50px' }}
      data-testid="listagemPacientes"
    >
      {hasPermission('CREATE_PACIENTE') && (
        <ButtonNew
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() =>
            navigate('/cadastrar-paciente', { state: { url: '/pacientes' } })
          }
          data-testId="button-nova_paciente"
        >
          Nova Paciente
        </ButtonNew>
      )}

      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        <TableContainer>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 1,
            }}
          ></Box>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    data-testid={formatTestId('column-', column?.label)}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <p style={{ padding: '16px' }}>Carregando...</p>
            ) : (
              <TableBody>
                {props?.data?.length === 0 ? (
                  <TableRow>
                    <TableCell>Sem Resultados</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  <>
                    {props.data?.map((row: any, index: number) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          data-testid={`row-${index}`}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            key={row.cns}
                            data-testid={`row-${index}-cns`}
                          >
                            {row.cns
                              ? String(row.cns).toUpperCase()
                              : 'Digite um filtro'}
                          </TableCell>
                          <TableCell data-testid={`row-${index}-nome`}>
                            {String(row.nome).toUpperCase()}
                          </TableCell>
                          <TableCell
                            data-testid={`row-${index}-data_de_nascimento`}
                          >
                            {row.dt_nascimento
                              ? moment(row.dt_nascimento).format('DD/MM/YYYY')
                              : ''}
                          </TableCell>
                          <TableCell data-testid={`row-${index}-cpf`}>
                            {maskCpf(
                              ['0', '11111111111', undefined].includes(row.cpf)
                                ? ''
                                : row.cpf
                            )}
                          </TableCell>
                          <TableCell data-testid={`row-${index}-nome_da_mae`}>
                            {String(row.nome_mae).toUpperCase()}
                          </TableCell>
                          <TableCell data-testid={`row-${index}-button`}>
                            {row.nome &&
                            hasPermission('VIEW_PACIENTE_FICHA') ? (
                              <>
                                <CustomizedButton
                                  variant="outlined"
                                  data-testid={`button-${index}-ficha`}
                                  onClick={(a) => {
                                    visualizarPaciente(row);
                                  }}
                                  startIcon={<AssignmentIcon />}
                                >
                                  Visualizar
                                </CustomizedButton>
                                {/* <p style={{ color: 'red', fontSize: '10px' }}>
                                Atualizado em:{' '}
                                {formateDataWithHours(row.updated_at)}
                              </p> */}
                              </>
                            ) : undefined}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>

          <CustomTablePagination
            rowsPerPageOptions={[25, 50, 100]}
            setPage={setPage}
            component="div"
            count={props.totalItems || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={'Itens por página'}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} de ${
                count !== -1 ? count : `mais do que ${to}`
              }`;
            }}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}
