import { useEffect, useState } from 'react';
import moment from 'moment';

import { StyledAsteriskTextField } from '../../StyledAsteriskTextField/styles';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const PeriodoInput = ({ control, handleSetFilter }) => {
  const [valueInputPeriod, setValueInputPeriod] = useState<any>('Todos');
  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [period, setPeriod] = useState<any>({});
  const [year, setYear] = useState<any>('');
  const [endDayAndMonth, setEndDayAndMonth] = useState<any>();
  const [initialDayAndMonth, setInitialDayAndMonth] = useState<any>();
  const [valuePeriodoModal, setValuePeriodoModal] = useState<any>({
    label: 'Todos',
    value: 'all',
  });
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();

  const optionsPeriodo = [
    { label: 'Todos', value: 'all' },

    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];

  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };

  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-04-01');
    // setValueInputQuadrimestreSemestre('1º Quadrimestre');
    setPeriod(undefined);
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    // setValueInputQuadrimestreSemestre('1º Semestre');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-06-01');
    setPeriod(undefined);
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-12-01');

    setPeriod(undefined);
  };

  const setValuesAllPeriodSelected = () => {
    setValueInputPeriod('Todos');

    setPeriod({ periodo_inicio: '2022-01-01' });
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      setPeriod(undefined);
      setYear(undefined);
      setValueInputPeriod(null);
    }
    switch (newValue?.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();

        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      case 'all':
        setValuesAllPeriodSelected();
        break;
      default:
        break;
    }
  };

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    // if (valueInputPeriod === 'Quadrimestral') {
    //   setValueInputQuadrimestreSemestre('1º Quadrimestre');
    // }

    // if (valueInputPeriod === 'Semestral') {
    //   setValueInputQuadrimestreSemestre('1º Semestre');
    // }
    setValueInputQuadrimestreSemestre('');
    if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_fim: `${newValue}-12-31`,
        periodo_inicio: `${newValue}-01-01`,
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    switch (newValue) {
      case '1º Semestre':
        setPeriod({
          periodo_fim: `${year}-06-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Semestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-06-01`,
        });
        break;
      case '1º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-05-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-09-01`,
          periodo_inicio: `${year}-05-01`,
        });
        break;
      case '3º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-12-31`,
          periodo_inicio: `${year}-09-01`,
        });
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setPeriod(null);
    handleSetFilter(null, 'periodo');
    setOpenModalPeriodo(false);
    setPeriod(null);
    setYear(null);
    setValueInputPeriod(null);
    setEndDayAndMonth(null);
    setInitialDayAndMonth(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
  };

  const validateInputsPeriod = () => {
    return new Promise((resolve, reject) => {
      if (valuePeriodoModal?.value === 'yearly' && !year) {
        resolve(false);
      }

      if (
        (valuePeriodoModal?.value === 'four_months' ||
          valuePeriodoModal?.value === 'six_months') &&
        (!valueInputQuadrimestreSemestre || !year)
      ) {
        resolve(false);
      }

      resolve(true);
    });
  };

  useEffect(() => {
    if (period && period.periodo_inicio && period.periodo_fim) {
      handleSetFilter(
        moment(period.periodo_inicio, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_inicio'
      );
      handleSetFilter(
        moment(period.periodo_fim, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_fim'
      );
    } else {
      handleSetFilter('Período: Todos os períodos', 'periodo_inicio');
      handleSetFilter('', 'periodo_fim');
    }
  }, [period]);
  return (
    <>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue: any) => {
                  handleSelectPeriodModal(newValue);
                }}
                options={optionsPeriodo}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod === 'Últimos 12 meses' ||
              valueInputPeriod === 'Todos' ||
              !valueInputPeriod ? undefined : (
                <Autocomplete
                  options={['2024', '2023', '2022']}
                  onChange={(_, newValue) => {
                    handleChangeWhenYearSelected(newValue);
                  }}
                  value={year ? year : ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione o Ano" />
                  )}
                />
              )}
              {}

              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  disabled={!year}
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? optionsQuadrimestre
                      : optionsSemestre
                  }
                  value={
                    valueInputQuadrimestreSemestre
                      ? valueInputQuadrimestreSemestre
                      : ''
                  }
                  onChange={(_, newValue) => {
                    handleChangeSemestreOrQuadrimestre(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!year}
                      label={
                        valueInputPeriod === 'Quadrimestral'
                          ? 'Selecione o Quadrimestre'
                          : 'Selecione o Semestre'
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
            }}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const validated = await validateInputsPeriod();

              if (validated) {
                setOpenModalPeriodo(false);
              }
            }}
            endIcon={<CheckIcon />}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
      <Controller
        name="periodo"
        control={control}
        render={({}) => {
          return (
            <TextField
              data-testid="periodoInput"
              fullWidth
              size="small"
              label="Período"
              value={valueInputPeriod ? valueInputPeriod : ''}
              variant="outlined"
              InputLabelProps={{ shrink: !!valueInputPeriod }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => setOpenModalPeriodo(true)}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                ),
              }}
            />
          );
        }}
      />
    </>
  );
};

export default PeriodoInput;
