import { useState, useEffect } from 'react';
import { optionsProcedimento } from '../../../../../utils/variables';

interface ProcedureOption {
  value: string;
  label: string;
}

const sortProcedures = (procedures: ProcedureOption[]): ProcedureOption[] => {
  return [...procedures].sort((a, b) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );
};

const filterProceduresByType = (
  type: 'coleta' | 'resultado'
): ProcedureOption[] => {
  let filtered: ProcedureOption[];

  if (type === 'coleta') {
    filtered = optionsProcedimento.filter((proc) => proc.value !== 'CITOLOGIA');
  } else if (type === 'resultado') {
    filtered = optionsProcedimento.filter(
      (proc) => proc.value !== 'COLPOSCOPIA'
    );
  } else {
    filtered = optionsProcedimento;
  }

  return sortProcedures(filtered);
};

const useFilteredProcedures = () => {
  const [coletaProcedures, setColetaProcedures] = useState<ProcedureOption[]>(
    []
  );
  const [resultadoProcedures, setResultadoProcedures] = useState<
    ProcedureOption[]
  >([]);

  useEffect(() => {
    setColetaProcedures(filterProceduresByType('coleta'));
    setResultadoProcedures(filterProceduresByType('resultado'));
  }, []);

  return {
    coletaProcedures,
    resultadoProcedures,
  };
};

export default useFilteredProcedures;
