import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomTablePagination from '../../PanelCustomTablePagination';

interface TableExamesHistopatologiaProps {
  data: Array<{
    id: number;
    nome: string;
    totaisIntraColpo: number;
    histopatologiasRealizadas: number;
    histopatologiasARealizar: number;
    histopatologiaSemRelacao: number;
  }>;
  totals: {
    totalIntraColpo: number;
    totalHistopatologiasRealizadas: number;
    totalHistopatologiasARealizar: number;
    totalHistopatologiaSemRelacao: number;
  };
  origemSelecionada: string;
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  localizacaoType: string;
}

export default function TableExamesHistopatologia({
  data,
  totals,
  origemSelecionada,
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRowsPerPageChange,
  localizacaoType,
}: TableExamesHistopatologiaProps) {
  const getColumns = () => {
    if (origemSelecionada === 'Biópsia') {
      return [
        { id: 'nome', label: localizacaoType || 'Localização', minWidth: 170 },
        {
          id: 'totaisIntraColpo',
          label: 'Mulheres com Colposcopia para Realizar Biopsia',
          minWidth: 170,
        },
        {
          id: 'histopatologiasRealizadas',
          label: 'Histopatologia Realizadas com Colposcopia Prévia',
          minWidth: 170,
        },
        {
          id: 'histopatologiasARealizar',
          label: 'Histopatologias a Realizar',
          minWidth: 170,
        },
        {
          id: 'histopatologiaSemRelacao',
          label: 'Histopatologia Realizadas sem Colposcopias Prévia',
          minWidth: 170,
        }
      ];
    } else {
      return [
        { id: 'nome', label: localizacaoType || 'Localização', minWidth: 170 },
        {
          id: 'totaisIntraColpo',
          label: 'Mulheres com Tratamento LIEAG',
          minWidth: 170,
        },
        {
          id: 'histopatologiasRealizadas',
          label: 'Histopatologia Realizadas com Tratamento LIEAG Prévio',
          minWidth: 170,
        },
        {
          id: 'histopatologiasARealizar',
          label: 'Histopatologia a Realizar',
          minWidth: 170,
        },
        {
          id: 'histopatologiaSemRelacao',
          label: 'Histopatologia Realizadas sem Tratamento LIEAG Prévio',
          minWidth: 170,
        }
      ];
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(newRowsPerPage);
    onPageChange(1);
  };

  const renderNumber = (value: number) => (
    <Box sx={{ width: '60px', textAlign: 'left' }}>
      {value}
    </Box>
  );

  const columns = getColumns();

  return (
    <Box className="table-container">
      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
            className="table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5}>Sem Resultados</TableCell>
                </TableRow>
              ) : (
                <>
                  {data.map((row) => (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>{row.nome}</TableCell>
                      <TableCell>{renderNumber(row.totaisIntraColpo)}</TableCell>
                      <TableCell>{renderNumber(row.histopatologiasRealizadas)}</TableCell>
                      <TableCell>{renderNumber(row.histopatologiasARealizar)}</TableCell>
                      <TableCell>{renderNumber(row.histopatologiaSemRelacao-row.histopatologiasRealizadas)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Total
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: '60px', textAlign: 'left', fontWeight: 'bold' }}>
                        {totals.totalIntraColpo}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: '60px', textAlign: 'left', fontWeight: 'bold' }}>
                        {totals.totalHistopatologiasRealizadas}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: '60px', textAlign: 'left', fontWeight: 'bold' }}>
                        {totals.totalHistopatologiasARealizar}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: '60px', textAlign: 'left', fontWeight: 'bold' }}>
                        {totals.totalHistopatologiaSemRelacao-totals.totalHistopatologiasRealizadas}
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          page={currentPage - 1}
          rowsPerPage={itemsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`
          }
        />
      </Paper>
    </Box>
  );
}