/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useStyles } from '../ViewExamStyle';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { TokenData, User } from '../../../components/ModalStatus';
import jwtDecode from 'jwt-decode';
import { getAccessToken } from '../../../lib/auth';
import { Escolha, Motivo } from './Hpv';
import { Anamnese } from '../../RegisterExam/Exams/Hpv';
import api from '../../../api';
import DownloadIcon from '@mui/icons-material/Download';

enum RedeAtendimento {
  RedeSus = 1,
  SaudeSuplementar,
}

enum Origem {
  Biopsia = 1,
  PecaCirurgica,
}

enum MargemCirurgica {
  Livre = 1,
  Comprometidas,
  NaoAvaliaveis,
}

enum NeoplasticaEscamosa {
  Nic1 = 1,
  Nic2,
  Nic3,
  CeMicroinvasivo,
  CeInvasivo,
  NaoAvaliavel,
}

interface Laboratory {
  id: number;
  razao_social: string;
  nome_fantasia: string | null;
  cnes: string;
  cnpj_mantenedor: string;
  inativo: boolean;
  dt_inicio: Date;
  dt_fim: Date | null;
}

interface Pessoa {
  nome: string;
  id: number;
  cns: string;
}

interface Profissional {
  pessoa: Pessoa;
  cbo: string;
  cns: string;
}

interface IHistopatologia {
  dt_coleta: Date;
  rede_atendimento: RedeAtendimento;
  protocolo: string;
  dt_resultado: Date;
  origem: Origem;
  margem_cirurgica: MargemCirurgica;
  metaplasia_escamosa: boolean;
  polipo_endocervical: boolean;
  cervicite_cronica: boolean;
  hpv: boolean;
  neoplastia_escamosa: NeoplasticaEscamosa;
  adenocarcionoma_in_situ: boolean;
  adenocarcionoma_invasor: boolean;
  outras_neoplasias: boolean;
  diagnostico_descritivo: string;
  pessoaId: number;
  profissionalId: number;
  profissionalCNS: string;
  laboratorioResponsavel: Laboratory;
  responsavelId: number;
  responsavelCNS: string;
  estabelecimentoSaudeId: number;
  profissional: Profissional;
}

interface Inputs {
  histopatologia: IHistopatologia;
  pessoaId: number;
  profissionalId: number;
  responsavelId: number;
  estabelecimentoSaudeId: number;
  anamnese: Anamnese;
}

const schema = Yup.object({});

export const Histopatologia = ({ examId, procedimentoId }) => {
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const styles = useStyles();
  const { id } = useParams();
  const {
    setValue,
    formState: { errors },
    control,
    watch,
    register,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const [user, setUser] = useState({} as User);
  const [checkedMenstruacao] = useState();

  useEffect(() => {
    if (data) {
      setValue('histopatologia', data);
      setValue('anamnese', data.anamnese);
    }
  }, [data]);

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  const styleCheckBox = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const handleBackPage = () => {
    navigate(`/pacientes/${id}`);
  };

  useEffect(() => {
    const token: TokenData = jwtDecode(getAccessToken());
    if (token) {
      setUser(token.user);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const responseData = await api.get(`/histopatologia/${examId}`);

      setData(responseData.data);
    };

    getData();
  }, [examId]);

  const rhf = watch();

  const generatePDF = async () => {
    try {
      const response = await api.get(
        `/reports/histopatologia/exame/${procedimentoId}`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  return (
    <Box mt={3}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Button
          variant="outlined"
          onClick={() => generatePDF()}
          startIcon={<DownloadIcon />}
        >
          EXPORTAR
        </Button>
      </Box>
      <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
        <i>* Resposta obrigatória</i>
      </p>

      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <Box p={3}>
          <Typography variant="h5" fontWeight="bold">
            Identificação do Laboratório
          </Typography>

          <Box my={3}>
            <Divider />
          </Box>

          <FormControl>
            <FormLabel
              required
              sx={styleFormLabel}
              id="rede-atendimento"
              // error={!!errors.rede_atendimento}
              disabled={!!examId}
            >
              Rede de Atendimento
            </FormLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <RadioGroup onChange={onChange} value={value} row>
                  <FormControlLabel
                    value={1}
                    control={<Radio checked={value === 1} />}
                    label="Rede SUS"
                    disabled
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio checked={value === 2} />}
                    label="Saúde Suplementar"
                    disabled
                  />
                </RadioGroup>
              )}
              name="histopatologia.rede_atendimento"
              control={control}
            />
            {/* <FormHelperText>{errors?.rede_atendimento?.message}</FormHelperText> */}
          </FormControl>

          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="CNES do Laboratório"
                  size="small"
                  value={data?.laboratorioResponsavel?.cnes || ''}
                  variant="outlined"
                  className={styles.textField}
                  onChange={() => {}}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <StyledAsteriskTextField
                      fullWidth
                      required
                      disabled
                      label="Laboratório Responsável pelo Laudo"
                      size="small"
                      variant="outlined"
                      name="histopatologia.laboratorioResponsavel"
                      sx={{ backgroundColor: '#F0F0F0' }}
                      onChange={onChange}
                      value={data?.laboratorioResponsavel?.nome_fantasia || ''}
                      // error={!!errors?.laboratorio_responsavel}
                      // helperText={errors?.laboratorio_responsavel?.message}
                    />
                  )}
                  name="histopatologia.laboratorioResponsavel"
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Número do Protocolo do Exame no SISCAN"
                      size="small"
                      variant="outlined"
                      className={styles.textField}
                      onChange={onChange}
                      value={value ? value : ''}
                      disabled={!!examId}
                      type="number"
                    />
                  )}
                  name="histopatologia.protocolo"
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{ borderRadius: '10px', marginTop: '25px' }}
      >
        <Box p={3}>
          <Typography variant="h5" fontWeight="bold">
            Exame
          </Typography>
          <Box my={3}>
            <Divider />
          </Box>
          <Box sx={{ width: '20%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <Controller
                control={control}
                name="histopatologia.dt_coleta"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled
                    onChange={onChange}
                    mask="__/__/____"
                    value={value ? value : null}
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        required
                        {...params}
                        style={{ marginTop: '20px' }}
                        label="Data da Coleta"
                        size="small"
                        variant="outlined"
                        className={styles.textField}
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box my={3}>
            <FormControl error={errors.histopatologia?.origem && true} disabled>
              <FormLabel sx={styleFormLabel} required>
                Origem
              </FormLabel>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    onChange={onChange}
                    value={value ? value : ''}
                    row
                    sx={{ marginLeft: '15px' }}
                  >
                    <FormControlLabel
                      value={Origem.Biopsia}
                      control={<Radio sx={styleRadio} />}
                      label="Biópsia"
                    />
                    <FormControlLabel
                      value={Origem.PecaCirurgica}
                      control={<Radio sx={styleRadio} />}
                      label="Peças Cirúrgicas"
                    />
                  </RadioGroup>
                )}
                name="histopatologia.origem"
                control={control}
              />
            </FormControl>
          </Box>
          {watch('histopatologia.origem') === Origem.PecaCirurgica && (
            <Box my={3}>
              <FormControl
                error={errors.histopatologia?.margem_cirurgica && true}
                disabled
              >
                <FormLabel sx={styleFormLabel} required>
                  Margens Cirúrgicas
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                      sx={{ marginLeft: '15px' }}
                    >
                      <FormControlLabel
                        value={MargemCirurgica.Livre}
                        control={<Radio sx={styleRadio} />}
                        label="Livre"
                      />
                      <FormControlLabel
                        value={MargemCirurgica.Comprometidas}
                        control={<Radio sx={styleRadio} />}
                        label="Comprometidas"
                      />
                      <FormControlLabel
                        value={MargemCirurgica.NaoAvaliaveis}
                        control={<Radio sx={styleRadio} />}
                        label="Impossível de Serem Avaliadas"
                      />
                    </RadioGroup>
                  )}
                  name="histopatologia.margem_cirurgica"
                  control={control}
                />
              </FormControl>
            </Box>
          )}
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{ borderRadius: '10px', marginTop: '25px' }}
      >
        <Box p={3}>
          <Typography variant="h5" fontWeight="bold">
            Resultado do Exame
          </Typography>
          <Box my={3}>
            <Divider />
          </Box>
          <Box sx={{ width: '20%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <Controller
                control={control}
                name="histopatologia.dt_resultado"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled
                    onChange={onChange}
                    mask="__/__/____"
                    value={value ? value : null}
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        style={{ marginTop: '20px' }}
                        label="Data do Resultado"
                        required
                        size="small"
                        variant="outlined"
                        className={styles.textField}
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box my={3}>
            <FormLabel sx={styleFormLabel}>Lesões de Caráter Benigno</FormLabel>
            <Box
              sx={{
                marginLeft: '15px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        {...field}
                        checked={field.value ?? false}
                        sx={styleCheckBox}
                      />
                    }
                    label="Metaplasia Escamosa"
                  />
                )}
                name="histopatologia.metaplasia_escamosa"
              />
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        {...field}
                        checked={field.value ?? false}
                        sx={styleCheckBox}
                      />
                    }
                    label="Pólipo Endocervical"
                  />
                )}
                name="histopatologia.polipo_endocervical"
              />
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        {...field}
                        checked={field.value ?? false}
                        sx={styleCheckBox}
                      />
                    }
                    label="Cervicite Crônica Inespecífica"
                  />
                )}
                name="histopatologia.cervicite_cronica"
              />
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        {...field}
                        checked={field.value ?? false}
                        sx={styleCheckBox}
                      />
                    }
                    label="Alterações Citoarquiteturais compatíveis com Ação Vital (HPV)"
                  />
                )}
                name="histopatologia.hpv"
              />
            </Box>
          </Box>
          <Paper variant="outlined" sx={{ width: '100%' }}>
            <Typography m={3} style={{ fontWeight: '550px' }}>
              Lesões de Caráter Neoplásico
            </Typography>

            <Box my={3}>
              <Divider />
            </Box>

            <Box m={3}>
              <FormControl disabled>
                <FormLabel sx={styleFormLabel}>
                  Pré-Neoplásico Escamosa:
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                      sx={{
                        marginLeft: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <FormControlLabel
                        value={NeoplasticaEscamosa.Nic1}
                        control={<Radio sx={styleRadio} />}
                        label="NIC I (Displasia leve)"
                      />
                      <FormControlLabel
                        value={NeoplasticaEscamosa.Nic2}
                        control={<Radio sx={styleRadio} />}
                        label="NIC II (Displasia moderada)"
                      />
                      <FormControlLabel
                        value={NeoplasticaEscamosa.Nic3}
                        control={<Radio sx={styleRadio} />}
                        label="NIC III (Displasia acentuada / Carcinoma in situ)"
                      />
                      <FormControlLabel
                        value={NeoplasticaEscamosa.CeMicroinvasivo}
                        control={<Radio sx={styleRadio} />}
                        label="Carcinoma Epidermóide Microinvasivo"
                      />
                      <FormControlLabel
                        value={NeoplasticaEscamosa.CeInvasivo}
                        control={<Radio sx={styleRadio} />}
                        label="Carcinoma Epidermóide Invasivo"
                      />
                      <FormControlLabel
                        value={NeoplasticaEscamosa.NaoAvaliavel}
                        control={<Radio sx={styleRadio} />}
                        label="Carcinoma Epidermóide impossível avaliar invasão"
                      />
                    </RadioGroup>
                  )}
                  name="histopatologia.neoplastia_escamosa"
                  control={control}
                />
              </FormControl>
            </Box>
            <Box m={3} mb={3}>
              <FormLabel sx={styleFormLabel}>
                Pré-Neoplásico Glandular:
              </FormLabel>
              <Box
                sx={{
                  marginLeft: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          {...field}
                          checked={field.value ?? false}
                          sx={styleCheckBox}
                        />
                      }
                      label="Adenocarcinoma in situ"
                    />
                  )}
                  name="histopatologia.adenocarcionoma_in_situ"
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          {...field}
                          checked={field.value ?? false}
                          sx={styleCheckBox}
                        />
                      }
                      label="Adenocarcinoma invasor"
                    />
                  )}
                  name="histopatologia.adenocarcionoma_invasor"
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          {...field}
                          checked={field.value ?? false}
                          sx={styleCheckBox}
                        />
                      }
                      label="Outras Neoplasias Malignas"
                    />
                  )}
                  name="histopatologia.outras_neoplasias"
                />
              </Box>
            </Box>
          </Paper>

          <Box mt={3}>
            <Controller
              name="histopatologia.diagnostico_descritivo"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled
                  {...field}
                  multiline
                  rows={3}
                  sx={{ width: '100%' }}
                  label="Diagnóstico Descritivo"
                  InputLabelProps={{ shrink: !!data?.diagnostico_descritivo }}
                />
              )}
            />
          </Box>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{ borderRadius: '10px', marginTop: '24px' }}
      >
        <Box p={3}>
          <Typography variant="h5" fontWeight="bold">
            Profissional Responsável
          </Typography>
          <Box my={3}>
            <Divider />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                name="histopatologia.profissional.pessoa.nome"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <StyledAsteriskTextField
                      value={value ? value : ''}
                      required
                      disabled
                      variant="outlined"
                      sx={{ backgroundColor: '#F0F0F0' }}
                      label="Profissional Responsável pelo Resultado"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name="histopatologia.profissional.cbo"
                render={({ field: { onChange, value } }) => {
                  return (
                    <StyledAsteriskTextField
                      fullWidth
                      required
                      value={value ? value : ''}
                      label="CBO do Profissional"
                      size="small"
                      disabled
                      variant="outlined"
                      sx={{ backgroundColor: '#F0F0F0' }}
                    />
                  );
                }}
              ></Controller>
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name="histopatologia.profissional.cns"
                render={({ field: { onChange, value } }) => {
                  return (
                    <StyledAsteriskTextField
                      fullWidth
                      required
                      value={value ? value : ''}
                      label="CNS do Profissional"
                      size="small"
                      disabled
                      variant="outlined"
                      sx={{ backgroundColor: '#F0F0F0' }}
                    />
                  );
                }}
              ></Controller>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '68px',
          borderRadius: 1,
        }}
        mb={3}
      >
        <Button
          sx={{
            marginTop: '10px',
            backgroundColor: '#17bebb',
          }}
          variant="contained"
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
};
