import { verifyCns } from '../verifyCns';

const useValidateCns = ({ setError, clearErrors }) => {
  const validateCns = (cns) => {
    const cnsIsValid = verifyCns(cns);

    if (cnsIsValid) {
      clearErrors('cns');
    } else {
      setError('cns', {
        type: 'manual',
        message: 'Digite um CNS válido',
      });
    }
  };

  return { validateCns };
};

export default useValidateCns;
