import * as Yup from 'yup';

export const schema = Yup.object({
  rede_atendimento: Yup.string().required('Campo Obrigatório.'),
  dt_resultado: Yup.string().required('Campo Obrigatório.'),
  resultado: Yup.string().required('Campo Obrigatório'),
  profissionalId: Yup.object({
    id: Yup.string().required('Campo Obrigatório'),
  }),
  laboratorio_responsavel: Yup.object({
    id: Yup.string().required('Campo Obrigatório'),
    nome_fantasia: Yup.string(),
    cnes: Yup.string(),
  }),
  //   outras_neoplasias_malignas: Yup.boolean(),
  //   atipiaEscamosaIndeterminada: Yup.object().when('outras_neoplasias_malignas', {
  //     is: (outrasNeoplasias) => {
  //       return outrasNeoplasias === false;
  //     },
  //     then: Yup.object({
  //       id: Yup.number().required('Campo Obrigatório'),
  //     }),
  //     otherwise: Yup.object({
  //       id: Yup.number(),
  //     }).nullable(),
  //   }),
  //   atipiaGlandularIndeterminada: Yup.object().when(
  //     'outras_neoplasias_malignas',
  //     {
  //       is: (outrasNeoplasias) => {
  //         return outrasNeoplasias === false;
  //       },
  //       then: Yup.object({
  //         id: Yup.number().required('Campo Obrigatório'),
  //       }),
  //       otherwise: Yup.object({
  //         id: Yup.number(),
  //       }).nullable(),
  //     }
  //   ),
  //   atipiaOrigemIndefinida: Yup.object().when('outras_neoplasias_malignas', {
  //     is: (outrasNeoplasias) => {
  //       return outrasNeoplasias === false;
  //     },
  //     then: Yup.object({
  //       id: Yup.number().required('Campo Obrigatório'),
  //     }),
  //     otherwise: Yup.object({
  //       id: Yup.number(),
  //     }).nullable(),
  //   }),
  //   atipiaEscamosa: Yup.object().when('outras_neoplasias_malignas', {
  //     is: (outrasNeoplasias) => {
  //       return outrasNeoplasias === false;
  //     },
  //     then: Yup.object({
  //       id: Yup.number().required('Campo Obrigatório'),
  //     }),
  //     otherwise: Yup.object({
  //       id: Yup.number(),
  //     }).nullable(),
  //   }),
  //   atipiaGlandular: Yup.object().when('outras_neoplasias_malignas', {
  //     is: (outrasNeoplasias) => {
  //       return outrasNeoplasias === false;
  //     },
  //     then: Yup.object({
  //       id: Yup.number().required('Campo Obrigatório'),
  //     }),
  //     otherwise: Yup.object({
  //       id: Yup.number(),
  //     }).nullable(),
  //   }),
});
