import { Button, Collapse, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CollapseColumnText = ({
  text,
  valueToOpen,
  stateOpenRow,
  setStateOpenedRow,
  classes,
}) => {
  return (
    <>
      <Typography color="#000001de" variant="body2" fontSize="14px">
        {text?.length > 30 ? (
          <div>
            <p style={{ margin: 0 }}>
              {stateOpenRow === valueToOpen ? text : text?.slice(0, 15) + '...'}
            </p>

            {stateOpenRow === valueToOpen ? (
              <Button
                onClick={() => setStateOpenedRow('')}
                sx={{ textTransform: 'none' }}
                endIcon={<KeyboardArrowUpIcon className={classes.icon} />}
              >
                Ver menos
              </Button>
            ) : (
              <Button
                onClick={() => setStateOpenedRow(valueToOpen)}
                sx={{ textTransform: 'none' }}
                endIcon={<KeyboardArrowDownIcon className={classes.icon} />}
              >
                Ver mais
              </Button>
            )}
          </div>
        ) : (
          <p>{text}</p>
        )}
      </Typography>
    </>
  );
};

export default CollapseColumnText;
