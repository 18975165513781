import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR';

import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';

import useHandleChangesFilters from './hooks/useHandleChangesFilters';
import useRequestFilter from './hooks/useRequestFilter';
import useVerifyAccessLevel from './hooks/useVerifyAccessLevel';
import useSetPayload from './hooks/useSetPayload';
import useClearValues, { ErrorField } from './hooks/useClearValues';

import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import MultipleFilters from '../../../../components/MultipleFilters';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import {
  optionsIndicacaoEZT,
  optionsMotivoNaoAtendida,
  optionsOrigemHistopatologia,
  optionsPreNeoplasicaEscamosa,
  optionsPreNeoplasicaGlandular,
  optionsRealizarBiopsia,
  optionsResultadoCitologia,
  optionsResultadoHPV,
  optionsTipoAchados,
} from '../../../../utils/variables';
import api from '../../../../api';
import useFilteredProcedures from './hooks/useFilteredProcedures';
import ModalSelect, { Option } from '../../../../components/ModalSelect';

interface FilterItem {
  inputName: string;
  value: string;
}

const FiltersReportsConsolidado = ({
  setTableData,
  control,
  handleSubmit,
  setValue,
  watch,
  errors,
  setError,
  clearErrors,
  estadosSelected,
  setEstadosSelected,
  municipiosSelected,
  setMunicipiosSelected,
  estabelecimentosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  listFilter,
  setListFilter,
  listFilterShow,
  setListFilterShow,
  initialStateListFilter,
  period,
  setPeriod,
  periodResultado,
  setPeriodResultado,
  setListFilterToShow,
  disableInputEstado,
  dtColetaIni,
  setDataColetaIni,
  dtColetaFinal,
  setDataColetaFinal,
  dtResultadoIni,
  setDataResultadoIni,
  dtResultadoFinal,
  setDataResultadoFinal,
  equipesSelected,
  setEquipesSelected,
  setDisableEquipeInput,
}) => {
  const [disableOtherInputs, setDisableOtherInputs] = useState(true);
  const [submittedFilters, setSubmittedFilters] = useState<FilterItem[]>([]);

  const [, setFirstLoadPage] = useState(true);

  const [estados, setEstados] = useState<any[]>([]);
  const [, setIsLoadingEstados] = useState(false);
  const [, setCidades] = useState<any[]>([]);
  const [, setEstabelecimentos] = useState<any[]>([]);
  const [, setSelectedProcedimento] = useState<string | null>(null);
  const [showMotivoNaoAtendimento, setShowMotivoNaoAtendimento] =
    useState(false);

  const [atendimentoHpvAnchor, setAtendimentoHpvAnchor] =
    useState<HTMLElement | null>(null);
  const [atendimentoHpvSearch, setAtendimentoHpvSearch] = useState('');
  const [atendimentoHpvOptions, setAtendimentoHpvOptions] = useState<Option[]>([
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ]);

  const [motivoAnchor, setMotivoAnchor] = useState<HTMLElement | null>(null);
  const [motivoSearch, setMotivoSearch] = useState('');
  const [motivoOptions, setMotivoOptions] = useState<Option[]>(
    optionsMotivoNaoAtendida
  );

  const [resultadoHpvAnchor, setResultadoHpvAnchor] =
    useState<HTMLElement | null>(null);
  const [resultadoHpvSearch, setResultadoHpvSearch] = useState('');
  const [resultadoHpvOptions, setResultadoHpvOptions] =
    useState<Option[]>(optionsResultadoHPV);

  const [resultadoCitologiaAnchor, setResultadoCitologiaAnchor] =
    useState<HTMLElement | null>(null);
  const [resultadoCitologiaSearch, setResultadoCitologiaSearch] = useState('');
  const [resultadoCitologiaOptions, setResultadoCitologiaOptions] = useState<
    Option[]
  >(optionsResultadoCitologia);

  const [tipoAchadosAnchor, setTipoAchadosAnchor] =
    useState<HTMLElement | null>(null);
  const [tipoAchadosSearch, setTipoAchadosSearch] = useState('');
  const [tipoAchadosOptions, setTipoAchadosOptions] =
    useState<Option[]>(optionsTipoAchados);

  const [realizarBiopsiaAnchor, setRealizarBiopsiaAnchor] =
    useState<HTMLElement | null>(null);
  const [realizarBiopsiaSearch, setRealizarBiopsiaSearch] = useState('');
  const [realizarBiopsiaOptions, setRealizarBiopsiaOptions] = useState<
    Option[]
  >(optionsRealizarBiopsia);

  const [origemHistopatologiaAnchor, setOrigemHistopatologiaAnchor] =
    useState<HTMLElement | null>(null);
  const [origemHistopatologiaSearch, setOrigemHistopatologiaSearch] =
    useState('');
  const [origemHistopatologiaOptions, setOrigemHistopatologiaOptions] =
    useState<Option[]>(optionsOrigemHistopatologia);

  const [preNeoplasicaEscamosaAnchor, setPreNeoplasicaEscamosaAnchor] =
    useState<HTMLElement | null>(null);
  const [preNeoplasicaEscamosaSearch, setPreNeoplasicaEscamosaSearch] =
    useState('');
  const [preNeoplasicaEscamosaOptions, setPreNeoplasicaEscamosaOptions] =
    useState<Option[]>(optionsPreNeoplasicaEscamosa);

  const [preNeoplasicaGlandularAnchor, setPreNeoplasicaGlandularAnchor] =
    useState<HTMLElement | null>(null);
  const [preNeoplasicaGlandularSearch, setPreNeoplasicaGlandularSearch] =
    useState('');
  const [preNeoplasicaGlandularOptions, setPreNeoplasicaGlandularOptions] =
    useState<Option[]>(optionsPreNeoplasicaGlandular);

  const [indicacaoEztAnchor, setIndicacaoEztAnchor] =
    useState<HTMLElement | null>(null);
  const [indicacaoEztSearch, setIndicacaoEztSearch] = useState('');
  const [indicacaoEztOptions, setIndicacaoEztOptions] =
    useState<Option[]>(optionsIndicacaoEZT);

  const [errorInputDate] = useState<any>();
  const [errorInputDateResultado, setErrorInputDateResultado] = useState<any>();

  const [, setDisableFilters] = useState({
    estado: false,
    municipio: false,
    estabelecimento: false,
  });

  const { currentEstablishment } = useContext(EstablishmentContext);

  const location = useLocation();

  const { coletaProcedures, resultadoProcedures } = useFilteredProcedures();

  const { handleSetFilter, getCidadesEstabelecimentos, getEstabelecimento } =
    useHandleChangesFilters({
      setCidades,
      setEstabelecimentos,
      setValue,
      setListFilter,
      setListFilterShow,
      estados,
      listFilter,
      watch,
      coletaProcedures,
      resultadoProcedures,
    });

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    errorsFields,

    arrayEquipes,
    setArrayEquipes,

    setOptionsLaboratorios,
    setErrorsFields,
    setAllLaboratoriosOptions,
  } = useStatesFields();

  const { loadReportsConsolidado } = useRequestFilter({
    setTableData,
  });

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    dtResultadoFinal,
  });

  const { permissao } = useVerifyAccessLevel({
    setValue,
    getCidadesEstabelecimentos,
    getEstabelecimento,
    currentEstablishment,
    setDisableFilters,
    handleSetFilter,
    setTableData,
    watch,
  });

  const { validationSubmit } = useActions();

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    loadReportsConsolidado,
    watch,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    showTableData,
    setTableData,
    setShowTableData,
    setErrorsFields: setErrorsFields as Dispatch<SetStateAction<ErrorField[]>>,
    setListFilterToShow,
    setDataColetaIni,
    setDataColetaFinal,
    setDataResultadoFinal,
    setDataResultadoIni,
    setSubmittedFilters,
  });

  const clearValuesInputs = () => {};

  const hasNaoSelected = (values: string | string[]) => {
    if (!values) return false;

    const normalizedValues = Array.isArray(values) ? values : [values];

    return normalizedValues.length === 1 && normalizedValues[0] === 'Não';
  };

  const hasProcedimentoColeta = !!watch('procedimento_coleta');
  const hasProcedimentoResultado = !!watch('procedimento_resultado');

  const fnWhenFormIsValid = async () => {
    if (!errorInputDate && !errorInputDateResultado) {
      setTableData([]);

      // const currentFilters = listFilter.filter(
      //   (item) => item.value && item.value !== ''
      // );
      // console.log('Current filters for display:', currentFilters);
      // setListFilterShow(currentFilters);

      setShowTableData(true);

      await setPayload().then((payload) => {
        try {
          loadReportsConsolidado(payload);
        } catch (error) {
          console.error('Error loading reports:', error);
        }
      });
    }
  };

  const onSubmit = async () => {
    const atendimentoValue = watch('atendimentoTesteHpv');
    const motivoValue = watch('motivo_nao_atendimento');

    const isNao =
      Array.isArray(atendimentoValue) && atendimentoValue.includes('Não');
    const semMotivo =
      !motivoValue || (Array.isArray(motivoValue) && motivoValue.length === 0);

    if (isNao && atendimentoValue.length === 1 && semMotivo) {
      setError('motivo_nao_atendimento', {
        type: 'required',
        message: 'Campo Obrigatório',
      });
      return;
    }

    try {
      await setPayload();
      // console.log('Generated payload:', payload);

      await validationSubmit({
        fnWhenFormIsValid,
        estadosSelected,
        municipiosSelected,
        estabelecimentosSelected,
        setErrorsFields,
        setDisableOtherInputs,
        watch,
        setError,
        clearErrors,
      });

      setSubmittedFilters(listFilterShow);
    } catch (error) {
      console.error('Erro na validação:', error);
    }
  };

  const handleSetPeriod = (e) => {
    setPeriod(e.target.value);
    setDataColetaFinal(null);
    setDataColetaIni(null);

    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_coleta_inicio');
    });
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_coleta_fim');
    });

    handleSetFilter('', 'dt_coleta_inicio');
    handleSetFilter('', 'dt_coleta_fim');
  };

  const handleSetPeriodResultado = (e) => {
    setPeriodResultado(e.target.value);
    setDataResultadoIni(null);
    setDataResultadoFinal(null);

    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_resultado_inicio');
    });
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_resultado_fim');
    });

    handleSetFilter('', 'dt_resultado_inicio');
    handleSetFilter('', 'dt_resultado_fim');
  };

  const estabelecimentoInputValue = watch('estabelecimento_saude');

  const selectedChartIsResult = !!localStorage.getItem('result');
  const selectedChartIsTypeVirus = !!localStorage.getItem('typeVirus');
  const selectedChartIsReason = !!localStorage.getItem('reasonNotProcedure');
  const selectedChartOriginCollection =
    localStorage.getItem('collectionOrigin');

  const setListFilterDisplayByCharts = () => {
    if (selectedChartIsResult) {
      const value = localStorage.getItem('result');
      handleSetFilter(
        optionsResultadoHPV.find((item) => item.value === value)?.label,
        'resultado'
      );
    }
  };

  const [errorInputDateColeta, setErrorInputDateColeta] = useState<any>({});

  useEffect(() => {
    if (
      !!location.state ||
      selectedChartIsResult ||
      selectedChartIsTypeVirus ||
      selectedChartIsReason ||
      selectedChartOriginCollection
    ) {
      setListFilterDisplayByCharts();
    }
  }, []);

  useEffect(() => {
    setValue('estado', estadosSelected);
    setValue('municipio', municipiosSelected);
    setValue('estabelecimentoSaudeId', estabelecimentosSelected);
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected, setValue]);

  useEffect(() => {
    const loadEstados = async () => {
      try {
        setIsLoadingEstados(true);
        const response = await api.get('/estados/consolidado');
        const formattedEstados = response.data;

        setEstados(formattedEstados);
        setEstadosOptions(formattedEstados);
        setAllEstadosOptions(formattedEstados);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      } finally {
        setIsLoadingEstados(false);
      }
    };

    loadEstados();
  }, [setEstadosOptions, setAllEstadosOptions]);

  // useEffect(() => {
  //   if (!estabelecimentoInputValue) {
  //     setValue('motivo', null);
  //     setValue('idade_ini', null);
  //     setValue('idade_fin', null);
  //     setValue('realizou_atendimento', null);
  //     setValue('atendimento_status', null);
  //     setValue('inspecao_colo', null);
  //     setValue('responsavel_coleta', null);
  //     setValue('dt_coleta', null);
  //     setValue('resultado', null);
  //     setValue('estabelecimentoLaudoId', null);
  //     setValue('dt_resultado', null);
  //     setValue('search', null);

  //     initialStateListFilter.forEach((field) => {
  //       const isMunicipio = field.inputName === 'municipio';
  //       const isEstado = field.inputName === 'estado';
  //       if (isEstado || isMunicipio) {
  //         return;
  //       } else {
  //         handleSetFilter('', field.inputName);
  //       }
  //     });
  //   }
  // }, [
  //   estabelecimentoInputValue,
  //   handleSetFilter,
  //   initialStateListFilter,
  //   setValue,
  // ]);

  useEffect(() => {
    if (!estabelecimentoInputValue) {
      const fieldsToReset = [
        'motivo',
        'idade_ini',
        'idade_fin',
        'realizou_atendimento',
        'atendimento_status',
        'inspecao_colo',
        'responsavel_coleta',
        'dt_coleta',
        'resultado',
        'estabelecimentoLaudoId',
        'dt_resultado',
        'search',
      ];

      fieldsToReset.forEach((field) => setValue(field, null));

      const filtersToReset = initialStateListFilter
        .filter((field) => !['municipio', 'estado'].includes(field.inputName))
        .map((field) => field.inputName);

      setListFilter((prev) =>
        prev.map((item) =>
          filtersToReset.includes(item.inputName)
            ? { ...item, value: '' }
            : item
        )
      );

      setListFilterShow((prev) =>
        prev.filter((item) => !filtersToReset.includes(item.inputName))
      );
    }
  }, [estabelecimentoInputValue]);

  useEffect(() => {
    const atendimentoValues = watch('atendimentoTesteHpv') || [];

    console.log('Current atendimentoTesteHpv values:', atendimentoValues);

    const showMotivo = hasNaoSelected(atendimentoValues);

    console.log('Should show motivo:', showMotivo);

    if (showMotivo) {
      setShowMotivoNaoAtendimento(true);

      if (!watch('atendimento_status')) {
        setError('atendimento_status', {
          type: 'required',
          message: 'Campo Obrigatório',
        });
      }
    } else {
      setShowMotivoNaoAtendimento(false);

      setValue('atendimento_status', null);
      clearErrors('atendimento_status');
    }
  }, [clearErrors, setError, setValue, watch]);

  // useEffect(() => {
  //   const isAllEmpty = !!listFilter.find((item) => item.value !== '') === false;

  //   if (isAllEmpty) {
  //     setListFilterShow([]);
  //   }
  // }, [listFilter, setListFilterShow]);

  useEffect(() => {
    const hasEstabelecimentoSelected = estabelecimentosSelected.length > 0;

    if (hasEstabelecimentoSelected) {
      setDisableOtherInputs(false);
    }
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

  useEffect(() => {
    if (
      optionsMunicipios?.length > 0 &&
      municipiosSelected?.length === optionsMunicipios?.length
    ) {
      setDisableOtherInputs(false);
    } else if (municipiosSelected?.length === 0) {
      setDisableOtherInputs(true);
    }
  }, [optionsMunicipios, municipiosSelected]);

  const searchInput = watch('search');

  useEffect(() => {
    if (!searchInput) {
      clearErrors('search');
    }
  }, [clearErrors, searchInput]);

  useEffect(() => {
    const dtIni = moment(dtColetaIni, 'DD-MM-YYYY');
    const dtFin = moment(dtColetaFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDateColeta({
        ini: '',
        fin: 'Data final deve ser maior que a data inicial',
      });
    } else {
      setErrorInputDateColeta('');
    }
  }, [dtColetaFinal, dtColetaIni]);

  useEffect(() => {
    const dtIni = moment(dtResultadoIni, 'DD-MM-YYYY');
    const dtFin = moment(dtResultadoFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDateResultado({
        fin: 'Data final deve ser maior que a data inicial',
        ini: '',
      });
    } else {
      setErrorInputDateResultado('');
    }
  }, [dtResultadoIni, dtResultadoFinal]);

  useEffect(() => {
    const atendimentoValue = watch('atendimentoTesteHpv');
    if (atendimentoValue !== 'Não') {
      setValue('atendimento_status', null);
      setShowMotivoNaoAtendimento(false);
    } else {
      setShowMotivoNaoAtendimento(true);
    }
  }, [setValue, watch]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DisplayFilter style={{ marginTop: '24px' }}>
            {!submittedFilters?.find((item) => item.value !== '') && (
              <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                Os filtros selecionados aparecerão aqui.
              </p>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              {submittedFilters
                .filter((item) => item.value !== '')
                .map((item) => {
                  // Remove o prefixo indesejado se existir
                  const displayValue = item.value.replace(
                    /^linha \d+ MultipleFilters: /,
                    ''
                  );
                  return (
                    <ItemsDisplayFilter key={item.inputName}>
                      {displayValue}
                    </ItemsDisplayFilter>
                  );
                })}
            </div>
          </DisplayFilter>
        </Grid>
        <MultipleFilters
          requiredMunicipio={estadosSelected.length > 1 ? false : true}
          // disableRequiredEstabelecimento={municipiosSelected.length !== 1}
          setFirstLoadPage={setFirstLoadPage}
          clearValuesInputs={clearValuesInputs}
          setDisableOtherInputs={setDisableOtherInputs}
          setAllLaboratoriosOptions={setAllLaboratoriosOptions}
          setOptionsLaboratorios={setOptionsLaboratorios}
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          mdEstado={4}
          mdMunicipio={4}
          mdEstabelecimento={4}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          equipesSelected={equipesSelected}
          setEquipesSelected={setEquipesSelected}
          setDisableEquipeInput={setDisableEquipeInput}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
          arrayEquipes={arrayEquipes}
          setArrayEquipes={setArrayEquipes}
        />
        {/* <Grid
          item
          xs={12}
          md={hasNaoSelected(watch('atendimentoTesteHpv')) ? 3 : 4}
        >
          <Controller
            name="atendimentoTesteHpv"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Realizou o atendimento"
                inputName="atendimentoTesteHpv"
                handleSetFilter={handleSetFilter}
                placeholder="Pesquisar por atendimento"
                options={atendimentoHpvOptions}
                allOptions={atendimentoHpvOptions}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={atendimentoHpvSearch}
                anchorEl={atendimentoHpvAnchor}
                setAnchorEl={setAtendimentoHpvAnchor}
                setSearchValue={setAtendimentoHpvSearch}
                setOptions={setAtendimentoHpvOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];

                  setValue('atendimentoTesteHpv', selectedValues);
                  onChange(selectedValues);

                  const hasNao = selectedValues.includes('Não');

                  if (hasNao && selectedValues.length === 1) {
                    setShowMotivoNaoAtendimento(true);
                    if (!watch('motivo_nao_atendimento')) {
                      setError('motivo_nao_atendimento', {
                        type: 'required',
                        message: 'Campo Obrigatório',
                      });
                    }
                  } else {
                    setShowMotivoNaoAtendimento(false);
                    clearErrors('motivo_nao_atendimento');
                  }

                  return selectedValues;
                }}
              />
            )}
          />
        </Grid>

        {showMotivoNaoAtendimento && (
          <Grid item xs={12} md={3}>
            <Controller
              name="motivo_nao_atendimento"
              control={control}
              defaultValue={[]}
              rules={{ required: showMotivoNaoAtendimento }}
              render={({ field: { onChange, value } }) => (
                <ModalSelect
                  label="Motivo do Não Atendimento"
                  inputName="motivo_nao_atendimento"
                  handleSetFilter={handleSetFilter}
                  required={showMotivoNaoAtendimento}
                  placeholder="Pesquisar por motivo"
                  options={motivoOptions}
                  allOptions={optionsMotivoNaoAtendida}
                  selectedValues={
                    Array.isArray(value) ? value : value ? [value] : []
                  }
                  displayKey="label"
                  valueKey="value"
                  disabled={disableOtherInputs}
                  searchValue={motivoSearch}
                  anchorEl={motivoAnchor}
                  errors={errors.motivo_nao_atendimento ? ['error'] : []}
                  errorMessage={
                    errors.motivo_nao_atendimento?.message ||
                    'Campo Obrigatório'
                  }
                  setAnchorEl={setMotivoAnchor}
                  setSearchValue={setMotivoSearch}
                  setOptions={setMotivoOptions}
                  setAllOptions={() => {}}
                  setSelectedValues={(newValue) => {
                    const selectedValues = Array.isArray(newValue)
                      ? newValue
                      : [newValue];
                    const mappedValues = selectedValues.map((value) => value);
                    onChange(mappedValues);
                    setValue('motivo_nao_atendimento', mappedValues);
                    if (mappedValues.length > 0) {
                      clearErrors('motivo_nao_atendimento');
                    }
                    return mappedValues;
                  }}
                />
              )}
            />
          </Grid>
        )} */}

        <Grid
          item
          xs={12}
          md={hasNaoSelected(watch('atendimentoTesteHpv')) ? 3 : 4}
        >
          <Controller
            name="resultadoTesteHpv"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Resultado do Teste de HPV"
                placeholder="Pesquisar por resultado"
                inputName="resultadoTesteHpv"
                handleSetFilter={handleSetFilter}
                options={resultadoHpvOptions}
                allOptions={optionsResultadoHPV}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={resultadoHpvSearch}
                anchorEl={resultadoHpvAnchor}
                setAnchorEl={setResultadoHpvAnchor}
                setSearchValue={setResultadoHpvSearch}
                setOptions={setResultadoHpvOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        resultadoHpvOptions.find((opt) => opt.value === val)
                          ?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'resultadoTesteHpv');
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={hasNaoSelected(watch('atendimentoTesteHpv')) ? 3 : 4}
        >
          <Controller
            name="resultadoCitologia"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Resultado da Citologia"
                placeholder="Pesquisar por resultado"
                inputName="resultadoCitologia"
                handleSetFilter={handleSetFilter}
                options={resultadoCitologiaOptions}
                allOptions={optionsResultadoCitologia}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={resultadoCitologiaSearch}
                anchorEl={resultadoCitologiaAnchor}
                setAnchorEl={setResultadoCitologiaAnchor}
                setSearchValue={setResultadoCitologiaSearch}
                setOptions={setResultadoCitologiaOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsResultadoCitologia.find(
                          (opt) => opt.value === val
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'resultadoCitologia');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="tipos_achados"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Tipos de Achados"
                placeholder="Pesquisar por tipos de achados"
                inputName="tipos_achados"
                handleSetFilter={handleSetFilter}
                options={tipoAchadosOptions}
                allOptions={optionsTipoAchados}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={tipoAchadosSearch}
                anchorEl={tipoAchadosAnchor}
                setAnchorEl={setTipoAchadosAnchor}
                setSearchValue={setTipoAchadosSearch}
                setOptions={setTipoAchadosOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsTipoAchados.find((opt) => opt.value === val)
                          ?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'tipos_achados');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="realizar_biopsia"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Realizar Biópsia"
                placeholder="Pesquisar por biópsia"
                inputName="realizar_biopsia"
                handleSetFilter={handleSetFilter}
                options={realizarBiopsiaOptions}
                allOptions={optionsRealizarBiopsia}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={realizarBiopsiaSearch}
                anchorEl={realizarBiopsiaAnchor}
                setAnchorEl={setRealizarBiopsiaAnchor}
                setSearchValue={setRealizarBiopsiaSearch}
                setOptions={setRealizarBiopsiaOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsRealizarBiopsia.find((opt) => opt.value === val)
                          ?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'realizar_biopsia');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="origem_histopatologia"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Origem da Histopatologia"
                placeholder="Pesquisar por origem"
                inputName="origem_histopatologia"
                handleSetFilter={handleSetFilter}
                options={origemHistopatologiaOptions}
                allOptions={optionsOrigemHistopatologia}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={origemHistopatologiaSearch}
                anchorEl={origemHistopatologiaAnchor}
                setAnchorEl={setOrigemHistopatologiaAnchor}
                setSearchValue={setOrigemHistopatologiaSearch}
                setOptions={setOrigemHistopatologiaOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsOrigemHistopatologia.find(
                          (opt) => opt.value === val
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'origem_histopatologia');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="pre_neoplasico_escamosa"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Pré-Neoplásico Escamosa"
                placeholder="Pesquisar por escamosa"
                inputName="pre_neoplasico_escamosa"
                handleSetFilter={handleSetFilter}
                options={preNeoplasicaEscamosaOptions}
                allOptions={optionsPreNeoplasicaEscamosa}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={preNeoplasicaEscamosaSearch}
                anchorEl={preNeoplasicaEscamosaAnchor}
                setAnchorEl={setPreNeoplasicaEscamosaAnchor}
                setSearchValue={setPreNeoplasicaEscamosaSearch}
                setOptions={setPreNeoplasicaEscamosaOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsPreNeoplasicaEscamosa.find(
                          (opt) => opt.value === val
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'pre_neoplasico_escamosa');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="pre_neoplasico_glandular"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Pré-Neoplásico Glandular"
                placeholder="Pesquisar por glandular"
                inputName="pre_neoplasico_glandular"
                handleSetFilter={handleSetFilter}
                options={preNeoplasicaGlandularOptions}
                allOptions={optionsPreNeoplasicaGlandular}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={preNeoplasicaGlandularSearch}
                anchorEl={preNeoplasicaGlandularAnchor}
                setAnchorEl={setPreNeoplasicaGlandularAnchor}
                setSearchValue={setPreNeoplasicaGlandularSearch}
                setOptions={setPreNeoplasicaGlandularOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsPreNeoplasicaGlandular.find(
                          (opt) => opt.value === val
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'pre_neoplasico_glandular');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="indicacao_ezt"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Indicação da EZT?"
                placeholder="Pesquisar por indicação"
                inputName="indicacao_ezt"
                handleSetFilter={handleSetFilter}
                options={indicacaoEztOptions}
                allOptions={optionsIndicacaoEZT}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={true} // desabilitada temporariamente
                searchValue={indicacaoEztSearch}
                anchorEl={indicacaoEztAnchor}
                setAnchorEl={setIndicacaoEztAnchor}
                setSearchValue={setIndicacaoEztSearch}
                setOptions={setIndicacaoEztOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsIndicacaoEZT.find((opt) => opt.value === val)
                          ?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'indicacao_ezt');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="nowrap"
            gap={2}
          >
            <Typography fontWeight="bold">Data da Coleta/Exame:</Typography>

            <Controller
              name="procedimento_coleta"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    disabled={disableOtherInputs}
                    options={coletaProcedures}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.label
                    }
                    onChange={(_, newValue) => {
                      onChange(newValue?.value || null);
                      setSelectedProcedimento(newValue?.value || null);

                      const currentDate = dtColetaIni;

                      if (!newValue) {
                        setDataColetaIni(null);
                        setDataColetaFinal(null);
                        setListFilterShow((prev) =>
                          prev.filter(
                            (f) =>
                              !['dt_coleta_inicio', 'dt_coleta_fim'].includes(
                                f.inputName
                              )
                          )
                        );
                      } else if (currentDate) {
                        handleSetFilter(
                          `${newValue.label} - Data da Coleta: ${moment(
                            currentDate,
                            'DD-MM-YYYY'
                          ).format('DD/MM/YYYY')}`,
                          'dt_coleta_inicio'
                        );
                      }
                    }}
                    value={
                      value
                        ? coletaProcedures.find((opt) => opt.value === value)
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-testid="procedimento_coleta"
                        size="small"
                        label="Procedimento"
                      />
                    )}
                    sx={{ width: '184px' }}
                  />
                );
              }}
            />

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={period}
                onChange={handleSetPeriod}
                row
              >
                <FormControlLabel
                  value="date"
                  data-testid="optionFiltrarDataColeta"
                  disabled={disableOtherInputs}
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  value="period"
                  data-testid="optionFiltrarPeriodoColeta"
                  disabled={disableOtherInputs}
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {period === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                flex="1"
                rowGap={1}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs || !hasProcedimentoColeta}
                    data-testid="periodDatepickerInitialDataColeta"
                    onChange={(date) =>
                      setDataColetaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      )
                    }
                    maxDate={new Date()}
                    value={
                      dtColetaIni
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Início da Data..."
                        size="small"
                        variant="outlined"
                        sx={{ width: '165px' }}
                        error={!!errorInputDate?.ini}
                        helperText={errorInputDate?.ini}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs || !hasProcedimentoColeta}
                    data-testid="periodDatepickerFinalDataColeta"
                    onChange={(date) => {
                      setDataColetaFinal(
                        date ? moment(date).format('DD/MM/YYYY') : null
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaFinal
                        ? moment(dtColetaFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data..."
                        error={!!errorInputDateColeta}
                        helperText={errorInputDateColeta.fin}
                        size="small"
                        variant="outlined"
                        sx={{ width: '165px' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {period === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs || !hasProcedimentoColeta}
                    data-testid="datepickerDataColeta"
                    onChange={(date) => {
                      const formattedDate = date
                        ? moment(date).format('DD/MM/YYYY')
                        : null;
                      setDataColetaIni(formattedDate);
                      setDataColetaFinal(formattedDate);

                      if (formattedDate && watch('procedimento_coleta')) {
                        const procedimento = coletaProcedures.find(
                          (p) => p.value === watch('procedimento_coleta')
                        );
                        handleSetFilter(
                          `${procedimento?.label} - Data da Coleta: ${formattedDate}`,
                          'dt_coleta_inicio'
                        );
                      }
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaIni && moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            gap={2}
          >
            <Typography fontWeight="bold">Data do Resultado:</Typography>

            <Controller
              name="procedimento_resultado"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    options={resultadoProcedures}
                    disabled={disableOtherInputs}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.label
                    }
                    onChange={(_, newValue) => {
                      onChange(newValue?.value || null);
                      setSelectedProcedimento(newValue?.value || null);

                      const currentDate = dtResultadoIni;
                      const currentDateFinal = dtResultadoFinal;

                      if (!newValue) {
                        setDataResultadoIni(null);
                        setDataResultadoFinal(null);
                        setListFilterShow((prev) =>
                          prev.filter(
                            (f) =>
                              ![
                                'dt_resultado_inicio',
                                'dt_resultado_fim',
                              ].includes(f.inputName)
                          )
                        );
                        handleSetFilter('', 'dt_resultado_inicio');
                        handleSetFilter('', 'dt_resultado_fim');
                      } else {
                        if (periodResultado === 'period') {
                          if (currentDate) {
                            handleSetFilter(
                              `${
                                newValue.label
                              } - Início da Data do Resultado: ${moment(
                                currentDate,
                                'DD-MM-YYYY'
                              ).format('DD/MM/YYYY')}`,
                              'dt_resultado_inicio'
                            );
                          }
                          if (currentDateFinal) {
                            handleSetFilter(
                              `${
                                newValue.label
                              } - Fim da Data do Resultado: ${moment(
                                currentDateFinal,
                                'DD-MM-YYYY'
                              ).format('DD/MM/YYYY')}`,
                              'dt_resultado_fim'
                            );
                          }
                        } else if (currentDate) {
                          handleSetFilter(
                            `${newValue.label} - Data do Resultado: ${moment(
                              currentDate,
                              'DD-MM-YYYY'
                            ).format('DD/MM/YYYY')}`,
                            'dt_resultado_inicio'
                          );
                        }
                      }
                    }}
                    value={
                      value
                        ? resultadoProcedures.find((opt) => opt.value === value)
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-testid="inputRealizouAtendimento"
                        size="small"
                        label="Procedimento"
                      />
                    )}
                    sx={{ width: '184px' }}
                  />
                );
              }}
            />

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={periodResultado}
                onChange={handleSetPeriodResultado}
                row
              >
                <FormControlLabel
                  data-testid="optionFiltrarDataResultado"
                  value="date"
                  disabled={disableOtherInputs}
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  data-testid="optionFiltrarPeriodoResultado"
                  value="period"
                  disabled={disableOtherInputs}
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {periodResultado === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                flex="1"
                gap={1}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    data-testid="periodDatepickerInitialDataResultado"
                    disabled={disableOtherInputs || !hasProcedimentoResultado}
                    onChange={(date) => {
                      setDataResultadoIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        `Início da Data do Resultado: ${moment(date).format(
                          'DD/MM/YYYY'
                        )}`,
                        'dt_resultado_inicio'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoIni
                        ? moment(dtResultadoIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Início da Data..."
                        error={!!errorInputDateResultado?.ini}
                        helperText={errorInputDateResultado?.ini}
                        size="small"
                        variant="outlined"
                        sx={{ width: '175px' }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs || !hasProcedimentoResultado}
                    data-testid="periodDatepickerFinalDataResultado"
                    onChange={(date) => {
                      setDataResultadoFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        `Fim da Data do Resultado: ${moment(date).format(
                          'DD/MM/YYYY'
                        )}`,
                        'dt_resultado_fim'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoIni
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data..."
                        error={!!errorInputDateResultado}
                        helperText={errorInputDateResultado?.fin}
                        size="small"
                        variant="outlined"
                        sx={{ width: '175px' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {periodResultado === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs || !hasProcedimentoResultado}
                    data-testid="datepickerDataResultado"
                    onChange={(date) => {
                      setDataResultadoFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      setDataResultadoIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        `Data do Resultado: ${moment(date).format(
                          'DD/MM/YYYY'
                        )}`,
                        'dt_resultado_inicio'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoFinal &&
                      moment(dtResultadoFinal, 'DD-MM-YYYY').isValid()
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data do Resultado"
                        size="small"
                        variant="outlined"
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Controller
            name="search"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  disabled={disableOtherInputs}
                  data-testid="inputSearchByNameCpf"
                  label="Pesquisar"
                  placeholder="Pesquisar por Nome ou CPF da paciente"
                  size="small"
                  error={!!errors?.search}
                  helperText={errors?.search?.message}
                  value={value ? value : ''}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'search');
                  }}
                  sx={{
                    width: '100%',
                    '.MuiOutlinedInput-root': {
                      paddingRight: '0',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        type="button"
                        onClick={() => {
                          validationSubmit({
                            fnWhenFormIsValid,
                            estadosSelected,
                            municipiosSelected,
                            estabelecimentosSelected,
                            setErrorsFields,
                            setDisableOtherInputs,
                            watch,
                            setError,
                            clearErrors,
                          });
                        }}
                      >
                        <SearchIcon sx={{ cursor: 'pointer', color: 'gray' }} />
                      </Button>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            data-testid="submitFilterReportsConsolidado"
          >
            FILTRAR
          </Button>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={clearValues}
            data-testid="clearFilterReportsConsolidado"
          >
            LIMPAR
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersReportsConsolidado;
