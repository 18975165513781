export function verifyCns(cns) {
  const firstChar = cns?.[0];

  if (firstChar == 7 || firstChar == 8 || firstChar == 9) {
    if (cns.trim().length !== 15) {
      return false;
    }

    let soma = 0;
    let resto;

    soma =
      parseInt(cns[0], 10) * 15 +
      parseInt(cns[1], 10) * 14 +
      parseInt(cns[2], 10) * 13 +
      parseInt(cns[3], 10) * 12 +
      parseInt(cns[4], 10) * 11 +
      parseInt(cns[5], 10) * 10 +
      parseInt(cns[6], 10) * 9 +
      parseInt(cns[7], 10) * 8 +
      parseInt(cns[8], 10) * 7 +
      parseInt(cns[9], 10) * 6 +
      parseInt(cns[10], 10) * 5 +
      parseInt(cns[11], 10) * 4 +
      parseInt(cns[12], 10) * 3 +
      parseInt(cns[13], 10) * 2 +
      parseInt(cns[14], 10) * 1;

    resto = soma % 11;

    return resto === 0;
  }

  if (firstChar == 1 || firstChar == 2) {
    if (cns.trim().length !== 15) {
      return false;
    }

    let soma = 0;
    let resto, dv;
    let pis = cns.substring(0, 11);
    let resultado = '';

    soma =
      parseInt(pis[0], 10) * 15 +
      parseInt(pis[1], 10) * 14 +
      parseInt(pis[2], 10) * 13 +
      parseInt(pis[3], 10) * 12 +
      parseInt(pis[4], 10) * 11 +
      parseInt(pis[5], 10) * 10 +
      parseInt(pis[6], 10) * 9 +
      parseInt(pis[7], 10) * 8 +
      parseInt(pis[8], 10) * 7 +
      parseInt(pis[9], 10) * 6 +
      parseInt(pis[10], 10) * 5;

    resto = soma % 11;
    dv = 11 - resto;

    if (dv === 11) {
      dv = 0;
    }

    if (dv === 10) {
      soma =
        parseInt(pis[0], 10) * 15 +
        parseInt(pis[1], 10) * 14 +
        parseInt(pis[2], 10) * 13 +
        parseInt(pis[3], 10) * 12 +
        parseInt(pis[4], 10) * 11 +
        parseInt(pis[5], 10) * 10 +
        parseInt(pis[6], 10) * 9 +
        parseInt(pis[7], 10) * 8 +
        parseInt(pis[8], 10) * 7 +
        parseInt(pis[9], 10) * 6 +
        parseInt(pis[10], 10) * 5 +
        2;

      resto = soma % 11;
      dv = 11 - resto;
      resultado = pis + '001' + dv.toString();
    } else {
      resultado = pis + '000' + dv.toString();
    }

    return cns === resultado;
  }
}
